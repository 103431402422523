import React, { useEffect, useState } from "react";
import axios from "axios";

export function UseContainers() {
    
  const [containers, setContainers] = useState([]);

  useEffect(() => {
    const fetchContainers = async () => {
      let name = "/JSON/Containers.json";

      const ctrns = await axios(name, {
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setContainers(ctrns.data);
    };

    fetchContainers();
  }, []);

  const GetContainers = () => {
    return [...containers];
  };

  return {
    GetContainers,
  };
}
