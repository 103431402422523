import React from 'react'
import ErrorDisplay from './ErrorDisplay'

function Textbox(props) {
  return (
    <div className={props.className}>
        <label>{props.LabelValue}{props.Info?(props.Info):(<></>)}</label>
        
        <input 
        type={props.Type} 
        name={props.Name}
        value={props.Value}
        onChange={props.ValueChanged}        
        />         
        <ErrorDisplay errors={props.errors} Name= {props.errorName} Number={props.Number} />
    </div>
  )
}

export default Textbox