import React from 'react'
import classes from './ItemSelectionLableWrapper.module.css'
import ItemSelection from './ItemSelection'
import SelectModal from '../SelectModal'
function ItemSelectionLableWrapper(props) {



  return (
    <div className={classes.lableWrapper}>
    <label className={`${classes.selectionLabel} ${props.lableClassName}`}>{props.label}:</label>
    {props.Info?(props.Info):<></>}
    <label className={`${classes.selectionLabel} ${props.valueLableClassName}`}>{props.value}</label>
    <button onClick={props.showModalHandler}>Choose</button>
    <SelectModal Show={props.showModal} HideModal={props.HideModal}>
        <ItemSelection
          items={props.items}          
          Max={props.Max}
          handleChosenSelection={props.handleChosenSelection}
          Instuction={props.Instuction}
        />      
        </SelectModal>
    </div>
  )
}

export default ItemSelectionLableWrapper