import React, { useEffect, useState } from "react";
import InfoTooltip from "../UI/InfoTooltip";
import ErrorDisplay from "../UI/ErrorDisplay";
import OrderItemPersonalisationOne from "./OrderItemPersonalisationOne";
import OrderItemPersonalisationTwo from "./OrderItemPersonalisationTwo";
import OrderItemPersonalisationWeddingAct from "./Wedding/OrderItemPersonalisationWeddingAct";
import ItemImage from "./ItemImage";
import classes from "./OrderItem.module.css";
import ProductInformation from "../UI/Modal/ProductInformation";
import Placeholder from "../UI/Placeholder";
import PlaceholderClasses from "../UI/PlaceHolderSet.module.css"

export default function OrderItem(props) {
  const textColourList = [
    "red",
    "orange",
    "yellow",
    "green",
    "light blue",
    "dark blue",
    "purple",
    "baby pink",
    "hot pink",
    "grey",
    "silver",
    "gold",
    "white",
    "black",
  ];

  const CheckErrors = (propertyName) => {
    const errorPropertyCollection = props.errors["Items"];
    if (errorPropertyCollection) {
      const idCheck = errorPropertyCollection.find((e) => e.Id == props.Number);
      if (idCheck) {
        let c = idCheck[propertyName];
        

        return idCheck[propertyName];
      }
    }
  };

  const [sizes, setSizes] = useState([]);
  const [colours, setColours] = useState([]);

  const personalisationSentence = "Personalisation can be left blank if preferred.";

  const SetCommonProperties = () => {
    // personalisation option 1 & 2
    props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemText", "");
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemTextColour",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemDesign",
      ""
    );
  };

  useEffect(() => {
    // This resets the selection to default...
    props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemSize", "");
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemColour",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemColour",
      ""
    );

    // personalisation option 1 & 2
    props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemText", "");
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemTextColour",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemDesign",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemDesignColour",
      ""
    );

    
   


    // wedding
    // props.handleChangeSpreadNestedDirect(
    //   "Items",
    //   props.Number,
    //   "ChildsName",
    //   ""
    // );
    // props.handleChangeSpreadNestedDirect(
    //   "Items",
    //   props.Number,
    //   "CouplesName",
    //   ""
    // );
    // props.handleChangeSpreadNestedDirect(
    //   "Items",
    //   props.Number,
    //   "DateOfWedding",
    //   ""
    // );
    // props.handleChangeSpreadNestedDirect(
    //   "Items",
    //   props.Number,
    //   "BrideGroomImage1",
    //   ""
    // );
    // props.handleChangeSpreadNestedDirect(
    //   "Items",
    //   props.Number,
    //   "BrideGroomImage2",
    //   ""
    // );
    

    // reloads the selection of sizes / colours, based on the Item selected.
    if (props.data.Items[props.Number].ItemName !== "") {

      const sizes = props.GetProductSize(props.data.Items[props.Number].ItemName)

      if (sizes.length == 1 ){      
      props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemSize", sizes[0]);
      }
      setSizes(sizes);

      const itemColours = props.GetProductColour(props.data.Items[props.Number].ItemName);

      if (itemColours.length == 1){

        props.handleChangeSpreadNestedDirect(
          "Items",
          props.Number,
          "ItemColour",
          itemColours[0]
        );
        
      }
      setColours(itemColours);
    }




  }, [props.data.Items[props.Number].ItemName]);


  const Wedding = () => {
   // wedding
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ChildsName",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "CouplesName",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "DateOfWedding",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "BrideGroomImage1",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "BrideGroomImage2",
      ""
    );
  }

  const ItemChangeHandler = (e) => {





    let val = e.target.value;

    let x = props.GetPersonalisationOption(val);
    
    
    if (x != undefined && x.Type === 3){
      Wedding();
    }


    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ItemName",
      val
    );
   
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "PersonalisationOption",
      x
    );
  };

  return (
    // <div className="frmItemRow">
    <div>
      <div className={classes.Select__Personalisation}>
        <div className={classes.itemheader}>
          <h3>Item {props.Number + 1}</h3>
        </div>
        <div className={classes.Item__Grid__Three}>
          <div className={classes.Item__GridItem}>
            <label>Name:</label>
            {props.setItem == null || props.setItem === false ? (
              <select
                id="ItemName"
                name="ItemName"
                value={props.data.Items[props.Number].ItemName}
                onChange={ItemChangeHandler}
              >
                <option value={""} disabled>
                  Select your Item
                </option>

                {props.GroupProductsInCategories().map((x, i) => (
                  <React.Fragment key={x[0]}>
                    <option value={""} id={i + x[0]} key={x[0]} disabled>
                      {"-" + x[0] + "-"}
                    </option>
                    {x[1].map((xx, ii) => (
                      //  <option value={xx.Name} key={ii + '-' + xx.Name}>
                      <option
                        value={xx.Name}
                        id={xx.Category + xx.Name}
                        key={xx.Category + xx.Name}
                      >
                        {xx.Name + " (£" + xx.Price + ")"}
                      </option>
                    ))}
                  </React.Fragment>
                ))}

                {/* {props.GetProductItems().map((x, i) => (
                  <option value={x.Name} key={i}>
                    {x.Name + " (£" + x.Price + ")"}
                  </option>
                ))} */}
              </select>
            ) : (
              <label>{props.data.Items[props.Number].ItemName}</label>
            )}

            {props.data.Items[props.Number].ItemName.length > 0?(<ProductInformation {...props.GetInformation(props.data.Items[props.Number].ItemName)}/>):(null)
            }
            

            {CheckErrors("ItemName") && (
              <p className="error">{CheckErrors("ItemName")}</p>
            )}
          </div>
          <div className={classes.Item__GridItem}>
            <label>Size: </label>
            {sizes && sizes.length > 1 ? (
              <select
                id="SizeList"
                name="Size"
                value={props.data.Items[props.Number].ItemSize}
                onChange={props.handleChange("Items", props.Number, "ItemSize")}
              >
                <option value={""} disabled>
                  Select your size
                </option>
                {sizes &&
                  sizes.map((x, i) => (
                    <option value={x} key={i}>
                      {x}
                    </option>
                  ))}
              </select>
            ) : <label className={classes.labelBox}>{sizes[0]}</label>}
            {CheckErrors("ItemSize") && (
              <p className="error">{CheckErrors("ItemSize")}</p>
            )}
          </div>
          <div className={classes.Item__GridItem}>
            <label>
              {props.GetOptionName(props.data.Items[props.Number].ItemName)}:
            </label>
            {colours && colours.length > 1 ? (
              <select
                id="ColourList"
                name="Colour"
                value={props.data.Items[props.Number].ItemColour}
                onChange={props.handleChange(
                  "Items",
                  props.Number,
                  "ItemColour"
                )}
              >
                <option value={""} disabled>
                  Select your{" "}
                  {props.GetOptionName(props.data.Items[props.Number].ItemName)}
                </option>
                {colours &&
                  colours.map((x, i) => (
                    <option value={x} key={i}>
                      {x}
                    </option>
                  ))}
              </select>
            ) : (
              <label className={classes.labelBox}>{colours[0]}</label>
            )}
            {CheckErrors("ItemColour") && (
              <p className="error">{CheckErrors("ItemColour")}</p>
            )}
          </div>
        </div>

        <div>
          {/* <div className="Item__GridItem"> */}
          {props.data.Items[props.Number].PersonalisationOption?.Type ? (
            // <fieldset>
            //   <legend>
            //     <h2>Personalisation</h2>
            //   </legend>
            <Placeholder className={PlaceholderClasses.placeholderItem}>
              <label className={PlaceholderClasses.placeholderLabel}>Personalisation</label>
              {props.data.Items[props.Number].PersonalisationOption?.Type ==
              1 ? (
                <>
                  <label className={classes.personalisationSentence}>{personalisationSentence}</label>
                <OrderItemPersonalisationOne
                  handleChange={props.handleChange}
                  data={props.data}
                  Number={props.Number}
                  errors={props.errors}
                  TextColours={props.GetTextColour(props.data.Items[props.Number].ItemName)}
                  className={classes.Personalisation__Item}
                  handleChangeSpreadNestedDirect={
                    props.handleChangeSpreadNestedDirect
                  }
                  />
                  </>
              ) : (
                <></>
              )}
              {props.data.Items[props.Number].PersonalisationOption?.Type ==
              2 ? (
                <>
                  <label className={classes.personalisationSentence}>{personalisationSentence}</label>
                <OrderItemPersonalisationTwo
                  handleChange={props.handleChange}
                  data={props.data}
                  Number={props.Number}
                  errors={props.errors}
                  TextColours={props.GetTextColour(props.data.Items[props.Number].ItemName)}
                  ItemDesignOptions={props.GetDesignOptions(
                    props.data.Items[props.Number].ItemName
                  )}
                  handleChangeSpreadNestedDirect={
                    props.handleChangeSpreadNestedDirect
                  }
                />
                </>
              ) : (
                <></>
              )}
              {props.data.Items[props.Number].PersonalisationOption?.Type ==
              3 ? (
                <OrderItemPersonalisationWeddingAct
                  handleChange={props.handleChange}
                  data={props.data}
                  Number={props.Number}
                  errors={props.errors}
                  TextColours={props.GetTextColour(props.data.Items[props.Number].ItemName)}
                  ItemDesignOptions={props.GetDesignOptions(
                    props.data.Items[props.Number].ItemName
                  )}
                  handleChangeSpreadNestedDirect={
                    props.handleChangeSpreadNestedDirect
                  }
                />
              ) : (
                <></>
              )}
              </Placeholder>
           
          ) : (
            <></>
          )}
        </div>
      </div>
      <ItemImage
        ItemName={props.data.Items[props.Number].ItemName}
        GetProductImage={props.GetProductImage}
      />
    </div>
  );
}
