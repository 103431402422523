import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styles from './AboutNavigation.module.css'

function AboutNavigation(props) {




  return (
    <nav className={styles.nav}>
            <ul className={styles.nav__list}>
                <li className={styles.nav__item}>
                    <Link to="/About-Us" className={`${styles.nav__link} ` + `${(props.heading == 'about-us'? styles.selected : '')}`}>About Us</Link>
                </li>
                <li className={styles.nav__item}>
                    <Link to={"Personalisation"} className={`${styles.nav__link} ` + `${(props.heading == 'personalisation'? styles.selected : '')}`}>PERSONALISATION</Link>
                </li>
                
                <li className={styles.nav__item}>
                    <Link to={"Gift-Wrap"} className={`${styles.nav__link} ` + `${(props.heading == 'gift-wrap'? styles.selected : '')}`}>Gift Wrap</Link>
                </li>
                <li className={styles.nav__item}>
                    <Link to={"Eco-Message"} className={`${styles.nav__link} ` + `${(props.heading == 'eco-message'? styles.selected : '')}`}>Eco Message</Link>
                </li>
                <li className={styles.nav__item}>
                    <Link to="The-Team" className={`${styles.nav__link} ` + `${(props.heading == 'the-team'? styles.selected : '')}`}>The Team</Link>
                </li>                
            </ul>
        </nav>
  )
}

export default AboutNavigation