import React, { useEffect, useState } from 'react'
import * as GoIcons from "react-icons/go";
import * as FaIcons from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import classes from './HoldingPageActions.module.css';

function HoldingPageActions(props) {

    const [greenIconStyle, setGreenIconStyle] = useState({});
const [greenStyle, setGreenStyle] = useState(classes.actIcn);

const [ActiveTab, setActiveTab] = useState("");
const ActiveStyle = `${classes.actIcn} ${classes.selected}`;
const ActiveIcon = {color:"white"}
const InActiveIcon = {};

    useEffect(() => {


        setActiveTab(props.Name);
        
        // if (props.Name == "Hamper"){
        //     setGreenIconStyle({
        //         color:"white"
        //     })
        //     setGreenStyle(`${classes.actIcn} ${classes.selected}` )
        // }
        // else{
        //     setGreenIconStyle({});
        //     setGreenStyle(`${classes.actIcn}`)
        // }

    },[props.Name])

  return (
    <div>
        <br/>
         {/* <IconContext.Provider value={{size: "5%", style:{marginRight: '.5rem', color:'black' }  }}> */}
         <IconContext.Provider value={{size: "10%", style:{marginRight: '.5rem', color:'black', top:'10px', position:"relative"}  }}>
         <div className={ActiveTab == "Aboutus"? ActiveStyle :  classes.actIcn}>
                <Link to="/About-Us">            
                    <FaIcons.FaBookOpen  style={ActiveTab == "Aboutus"? {...ActiveIcon} : {}}  />         
                    <h3>About Us</h3>                   
                </Link>                             
            </div>
            <div className={ActiveTab == "Products"? ActiveStyle :  classes.actIcn}>
                <Link to="/Products">            
                    <FaIcons.FaShoppingBag style={ActiveTab == "Products"? {...ActiveIcon} : {}}  />                        
                    <h3>Products</h3>                
                </Link>                
            </div>
            <div className={ActiveTab == "Hamper"? ActiveStyle :  classes.actIcn}>
                <Link to="/Hampers">                       
                    <FaIcons.FaShoppingBasket  style={ActiveTab == "Hamper"? {...ActiveIcon} : {}} />                        
                    <h3>Hampers</h3>                
                </Link>
            </div>
            <div className={ActiveTab == "Reviews"? ActiveStyle :  classes.actIcn}>
                <Link to="/Reviews">            
                    <GoIcons.GoComment  style={ActiveTab == "Reviews"? {...ActiveIcon} : {}} />
                    <h3>Reviews</h3>                
                </Link>
            </div>
            
        </IconContext.Provider>
        
        

    </div>
  )
}

export default HoldingPageActions