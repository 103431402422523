import React, { useEffect, useState } from "react";
import classes from "./ItemSelection.module.css";

function ItemSelection(props) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmSelected, setConfirmSelected] = useState(false);
  const checkmark = "\u2714";

  useEffect(() => {
    if (selectedItems.length == 0) {
      for (let x = 0; x < props.Max.length; x++) {
        setSelectedItems((item) => [...item, { index: -1, name: "" }]);
      }
    }
  }, [props.Max.length]);

  const AddItem = (i, itemIndex, v) => {
    let itemTmp = [...selectedItems];
    itemTmp[i] = { index: itemIndex, name: v };
    setSelectedItems(itemTmp);
  };

  const ItemSelectedList = (value, i) => {
    // do we have any empty slots to fill?
    const emptySlots = selectedItems.findIndex((s) => s.index == -1);

    // index of item selected
    const indexOfItem = selectedItems.findIndex((p) => p.index == i);

    // item selected isn't already in the list.
    if (indexOfItem < 0) {
      // check that the number of items with a value < props.Max
      const lengthOfSet = selectedItems.filter((c) => c.index != -1);

      // we have room to accomodate the item.
      if ( lengthOfSet.length < props.Max.length) {
        // find the first empty slot to add it to.
        const emptySlot = selectedItems.findIndex((c) => c.index == -1);
        AddItem(emptySlot, i, value);
      }
    } else {
      AddItem(indexOfItem, -1, "");
    }
  };

  const btnSelected = (e) => {
    e.preventDefault();
    // props.HandleItemSelction(itemOne.name, "BrideGroomImage1");
    // props.HandleItemSelction(itemTwo.name, "BrideGroomImage2");
    setConfirmSelected(true);
  };

  const btnConfirmation = (e) => {
    e.preventDefault();
    setConfirmSelected(false);
    props.handleChosenSelection(selectedItems);
  };

  const FindImage = (i) => {
    const selected = props.items.find((c) => c.Name == i);
    if (selected) {
      return selected.Img;
    }
  };

  const IsItemSelected = (index) => {};

  return (
    <>
      {!confirmSelected ? (
        <>
          {props.Instuction}
          <div className={classes.Item__Grid__Three}>
            {props.items.map((x, i) => (
              // <div className={classes.Item__GridItem} onClick={() => props.HandleItemSelction(x,"BrideGroomImage1")}>
              <div
                key={i}
                className={`${classes.Item__GridItem} ${
                  selectedItems?.findIndex((x) => x.index == i) >= 0
                    ? classes.Item__Selected
                    : ""
                }`}
                onClick={(e) => {
                  ItemSelectedList(x.Name, i);
                }}
              >
                <img src={x.Img} />
                {x.Name}
                {selectedItems?.findIndex((x) => x.index == i) >= 0
                  ? checkmark
                  : ""}
              </div>
            ))}
          </div>
          {selectedItems.filter((c) => c.index != -1).length == props.Max.length?(
          <button onClick={btnSelected}>Next</button>
          ):<></>}
        </>
      ) : (
        <div>
          <h2>You have selected:</h2>

          {selectedItems.map((x, i) => (
            <div key={i} className={classes.selectionOverview}>
              {props.Max[i]}
              {<img src={FindImage(x.name)} />}
              {x.name}
            </div>
          ))}
          <button onClick={btnConfirmation}>Ok</button>
          <button onClick={(e) => setConfirmSelected(false)}>Cancel</button>
        </div>
      )}
    </>
  );
}

export default ItemSelection;
