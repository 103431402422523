import React, { useEffect,useState }  from "react";
import TextArea from "../UI/TextArea";
import Dropdown from "../UI/Dropdown";
import Textbox from "../UI/Textbox";
import ClearPersonalisation from './ClearPersonalisation';
import SelectModal from "../UI/Modal/SelectModal";
import ItemSelection from "../UI/Modal/Selection/ItemSelection";
import ItemSelectionLableWrapper from "../UI/Modal/Selection/ItemSelectionLableWrapper";
import ErrorDisplay from "../UI/ErrorDisplay";
import classes from './OrderItem.module.css'
import PersonalisationTwoTextInfo from "../UI/Information/PersonalisationTwoTextInfo";
import modalClass from '../UI/Modal/Modal.module.css';
import PersonalisationTwoDesignInfo from "../UI/Information/PersonalisationTwoDesignInfo";

function OrderItemPersonalisationTwo(props) {
  const [showModal, setShowModal] = useState(false);

  const hideModalHandler = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  const showModalHandler = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

//   useEffect(() => {
//     // personalisation option 1 & 2
//     props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemText", "");
//     props.handleChangeSpreadNestedDirect(
//       "Items",
//       props.Number,
//       "ItemTextColour",
//       ""
//     );
//     props.handleChangeSpreadNestedDirect(
//       "Items",
//       props.Number,
//       "ItemDesign",
//       ""
//     );
    
 
//  },[]);

  const clearPersonalisation = (e) => {
    e.preventDefault();
    props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemText", "");
    props.handleChangeSpreadNestedDirect("Items",props.Number,"ItemTextColour","");
    props.handleChangeSpreadNestedDirect("Items",props.Number,"ItemDesign","");    ;
  };


  const handleItemSelction = (i, name) => {
    setShowModal(false);

    props.handleChangeSpreadNestedDirect("Items", props.Number, name, i);
  };
  
  const handleChosenSelection = (items) => {
    setShowModal(false);
   if (items.length == 1){
      handleItemSelction(items[0].name, "ItemDesign");      
   }
   
  }

  return (
    <>
    <div className={classes.Item__Grid__Two}>
    
      <TextArea
        LabelValue="Item Text:"
        type="text"
        Name="ItemText"
        Value={props.data.Items[props.Number].ItemText}
        ValueChanged={props.handleChange("Items", props.Number, "ItemText")}
        //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
        Columns="40"
        Rows="5"
        errors={props.errors}
        errorName={"ItemText"}
        Number={props.Number}
        Info = {<PersonalisationTwoTextInfo Location="Item" btnClass={modalClass.closeButton}/>}
      />
      <Dropdown
        LabelValue="Item Text Colour:"
        Name="ItemTextColour"
        Value={props.data.Items[props.Number].ItemTextColour}
        DefaultText="Select colour for item text"
        ValueChanged={props.handleChange(
          "Items",
          props.Number,
          "ItemTextColour"
        )}
        //    className={classes.Container__GridItem}
        Options={props.TextColours}
        errors={props.errors}
        errorName={"ItemTextColour"}
        Number={props.Number}
      />

      {/* <Dropdown
        LabelValue="Item Design:"
        type="text"
        Name="ItemDesign"
        Value={props.data.Items[props.Number].ItemDesign}
        ValueChanged={props.handleChange("Items", props.Number, "ItemDesign")}
        //  className={classes.Container__GridItem}
        Options={props.ItemDesignOptions}
        DefaultText="Select Item design"
        errors={props.errors}
        errorName={"ItemDesign"}
        Number={props.Number}
      /> */}
      
       
      
        <ItemSelectionLableWrapper
          items={props.ItemDesignOptions}          
          Max={['Item Design']}
          handleChosenSelection={handleChosenSelection}
          Instuction={<div>Please select <b>one</b> design. Then, click `next' to review your selection. To undo, click the selected design to remove and select the new one.</div> }
          label='Item Design'
          value={props.data.Items[props.Number].ItemDesign}
          showModalHandler={showModalHandler}
          showModal={showModal}
          HideModal={hideModalHandler}
          Info={<PersonalisationTwoDesignInfo Location="Item" btnClass={modalClass.closeButton}/>}
        />
      
    </div>
       <ClearPersonalisation clearPersonalisationHandler={clearPersonalisation}/>
       </>
  );
}

export default OrderItemPersonalisationTwo;
