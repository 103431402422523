import React, { useState } from "react";
import * as GoIcons from "react-icons/go";
import classes from "./PersonalisationInfo.module.css";
import ImageProvider from "../Images/ImageProvider";
import SelectModal from "../Modal/SelectModal";

function PersonalisationInfo({
  Title,
  Descirption,
  Image,
  Example,
  buttonClass,
  Exception,
  ExceptionImage
}) {
  const [showModal, setShowModal] = useState(false);
  const showModalHandler = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  const hideModalHandler = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  return (
    <div className={classes.Info}>
      <GoIcons.GoInfo onClick={showModalHandler}></GoIcons.GoInfo>
      <SelectModal
        Show={showModal}
        HideModal={hideModalHandler}
        BtnClass={buttonClass ? buttonClass : classes.btn__cls}
      >
        <div className={classes.InfoWrapper}>
          <label className={classes.Title}>{Title}</label>
          <div className={classes.InfoText}>{Descirption}</div>
          {Example != undefined? (
            
            <div className={classes.EmapleWrapper}>
                <label className={classes.ExampleWrapperLabel}>Example</label>
              <div className={classes.Example}>{Example}</div>
              <ImageProvider
                className={classes.ExampleImage}
                Img={Image}
                AltText="ExampleImage"
              />
            </div>
          ) : (<></>
          )}
          {Exception != undefined? (
              <div>
              <label className={classes.ExampleWrapperLabel}>Exception</label>
              <div className={classes.Exception}>{Exception}</div>              
              {ExceptionImage != undefined? (
                <ImageProvider className={classes.ExampleImage} Img={ExceptionImage} AltText="Exception Image" />
              ):(<></>)}
              </div>
          ):(<></>)
          }
        </div>
      </SelectModal>
    </div>
  );
}

export default PersonalisationInfo;
