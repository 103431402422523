import React from "react";
import classes from "./Personalisation.module.css";
import PersonalisationExampleItem from "./PersonalisationExampleItem";

function Personalisation() {
  return (
    <div className={classes.PersonalisationWrapper}>
      <h1>Personalisation</h1>
      <span>
        We are able to personalise many of our products. You can choose to have
        just text or just a design, or both. Personalisation can also be left
        blank if preferred. Please check the spellings, capital letters,
        punctuation, etc. before you submit the order form. We cannot use any
        saying or image that has been trademarked due to copyright reasons. If
        you change your mind, click the ‘clear personalisation’ button to reset
        and start again.  There is limited area for personalisation on some products. Please see some examples below.
      </span>
      <h2>Text</h2>
      <span>
        We have a range of fonts to use, so if you have a preferred font we can
        try to accommodate this (but it’s not guaranteed). Once you’ve typed
        your text, use the extra space in the box to let us know your desired
        font.
      </span>
      <h2>Design</h2>
      <span>
        Use this section to detail your desired design and we will try our best
        to accommodate you. We will advertise some designs across our social
        media platforms for you to use (for example ‘Christmas Design 1’). For
        products that are personalised with embroidery, there are set designs to
        choose from.
      </span>
      <h2>Colours</h2>
      <span>
        You can choose from a list of fourteen colours for your text and design:
        red, orange, yellow, green, light blue, dark blue, purple, baby pink,
        hot pink, grey, silver, gold, white, and black. You can choose a
        different colour for your design to your text. However, a colour MUST be
        selected if you’ve written in the text or design section. Some colours
        are pre-set for the embroidered designs.
      </span>

      <div className={classes.PersonalisationExampleWrapper}>
        <PersonalisationExampleItem
          Description={
            <p className={classes.Example__Item__Paragraph}>
              <b>Text Only: </b>
              for this personalisation ‘Gillian’ would be written in the Text
              section and then ‘black’ chosen from the Text Colour drop down
              list. No design was chosen.
            </p>
          }
          Img="/Images/Personalisation/1.jpg"
        />
        <PersonalisationExampleItem
          Description={
            <p className={classes.Example__Item__Paragraph}>
              <b>Text Only: </b>
              for this personalisation ‘HEAD CHEF Shelagh’ would be written in
              the Text section and then ‘white’ chosen from the Text Colour drop
              down list. No design was chosen. An additional comment could be
              added such as ‘Head Chef to be written in capitals on first line
              and Shelagh to be written in a script font on second line please’.
            </p>
          }
          Img="/Images/Personalisation/7.jpg"
        />
        <PersonalisationExampleItem
          Description={
            <p className={classes.Example__Item__Paragraph}>
              <b>Design only: </b>
              for this personalisation, no text was chosen, but ‘wake me up when
              it’s Christmas design’ would be in the design section and then
              ‘gold’ would be chosen from the Design Colour drop down list.
            </p>
          }
          Img="/Images/Personalisation/2.jpg"
        />
        <PersonalisationExampleItem
          Description={
            <p className={classes.Example__Item__Paragraph}>
              <b>Design only: </b>
              for this personalisation, the ‘birth block’ was chosen as the
              design. You would then need to use the text box to type all the
              information such as name, date, time and weight.
            </p>
          }
          Img="/Images/Personalisation/5.jpg"
        />
        <PersonalisationExampleItem
          Description={
            <p className={classes.Example__Item__Paragraph}>
              <b>Text and design: </b>
              for this personalisation ‘Molly & Rory’ would be written in the
              Text section and ‘black’ chosen from the Text Colour drop down
              list. Then, ‘a home with hearts’ written in the Design section
              with ‘black’ chosen from the Design Colour drop down list.
            </p>
          }
          Img="/Images/Personalisation/8.jpg"
        />
        <PersonalisationExampleItem
          Description={
            <p className={classes.Example__Item__Paragraph}>
              <b>Text and design: </b>
              for this personalisation ‘for Nora and Phoebe’ would be written in
              the Text section and ‘hot pink’ chosen from the Text Colour drop
              down list. Then, ‘TOYS title design’ written in the Design section
              with ‘black’ chosen from the Design Colour drop down list.
            </p>
          }
          Img="/Images/Personalisation/9.jpg"
        />
        <PersonalisationExampleItem
          Description={
            <p className={classes.Example__Item__Paragraph}>
              <b>Text and design: </b>
              for this personalisation ‘Baking STAR’ (note the use of capital
              letters here to get the desired look) would be written in the Text
              section and ‘black chosen from the Text Colour drop down list.
              Then, ‘a star’ written in the Design section with ‘black’ chosen
              from the Design Colour drop down list.
            </p>
          }
          Img="/Images/Personalisation/10.jpg"
        />
      </div>
    </div>
  );
}

export default Personalisation;
