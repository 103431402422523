import React from "react";
import classes from "./Details.module.css";

function ContactDetails(props) {
  return (
    <div className="ordFormContactDetails">
      <div className="ordFromHeader">Contact Details</div>
      <h5>Client's Details (person purchasing the hamper)</h5>
      <div className={classes.detailGrid__three}>
        <div>
          <label>Full Name:</label>
          <input
            name="FullName"
            type="text"
            value={props.data.ContactDetails.FullName}
            onChange={props.handleChangeSpread("ContactDetails", "FullName")}
          ></input>
          {props.errors.ContactDetailsFullName && (
            <p className="error">{props.errors.ContactDetailsFullName}</p>
          )}
        </div>
        <div>
          <label>Telephone No:</label>
          <input
            name="TelephoneNumber"
            type="text"
            value={props.data.ContactDetails.TelephoneNumber}
            onChange={props.handleChangeSpread(
              "ContactDetails",
              "TelephoneNumber"
            )}
          ></input>
          {props.errors.ContactDetailsTelephoneNumber && (
            <p className="error">
              {props.errors.ContactDetailsTelephoneNumber}
            </p>
          )}
        </div>
        <div>
          <label>Email Address:</label>
          <input
            name="Email"
            type="text"
            value={props.data.ContactDetails.Email}
            onChange={props.handleChangeSpread("ContactDetails", "Email")}
          ></input>
          {props.errors.ContactDetailsEmail && (
            <p className="error">{props.errors.ContactDetailsEmail}</p>
          )}
        </div>
      </div>
      <div className={classes.detailGrid__address}>
        <div className={classes.detailGrid__address__HomeAddress}>
          <label>Home Address:</label>
          <textarea
            name="HomeAddress"
            type="text"
            value={props.data.ContactDetails.HomeAddress}
            onChange={props.handleChangeSpread("ContactDetails", "HomeAddress")}
            cols="40"
            rows="5"
          ></textarea>
          {props.errors.ContactHomeAddress && (
            <p className="error">{props.errors.ContactHomeAddress}</p>
          )}
        </div>
        <div className={classes.detailGrid__address__postcode}>
          <label>Postcode:</label>
          <input
            name="Postcode"
            type="text"
            value={props.data.ContactDetails.Postcode}
            onChange={props.handleChangeSpread("ContactDetails", "Postcode")}
          ></input>
          {props.errors.ContactDetailsPostcode && (
            <p className="error">{props.errors.ContactDetailsPostcode}</p>
          )}
        </div>
      </div>
      <div className={classes.HearAbout}>
      <label>How did you hear about Match Hampers?</label>
          <input
            name="HearAbout"
            type="text"
            value={props.data.ContactDetails.HearAbout}
            onChange={props.handleChangeSpread("ContactDetails", "HearAbout")}
          ></input>
      </div>
    </div>
  );
}

export default ContactDetails;
