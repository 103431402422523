import logo from "./logo.svg";
import "./App.css";
// import Home from './Components/HomePage/home';
import OrderForm from "./Components/OrderForm";
// import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Hampers from "./Components/Hampers/Hampers";
import MhFooter from "./Components/UI/Footer/MhFooter";
import ProductsPage from "./Components/Products/ProductsPage";
import Reviews from "./Pages/Reviews/Reviews";
import Aboutus from "./Components/AboutUs/Aboutus";
import HomePage from "./Pages/Home/HomePage";
import Layout from "./Components/Shared/Layout";
import Aboutpage from './Pages/About/Aboutpage';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: "/Reviews", element: <Reviews /> },        
        {
          path: "/About-Us",
          element: <Aboutpage />,
          children: [{ index: true, element: <Aboutus />, path: ":section" }],
        },
        {
          path: "/Products",
          element: <ProductsPage />,
          children: [{ index: true, element: <Aboutus />, path: ":Selection" }],
        },
        {
          path: "/Hampers",
          element: <Hampers />},
          {
            path:"/Hampers/:ThemeName",
            element: <OrderForm />,
          children: [            
            { index: false, element: <OrderForm />, path: ":hamperName" }],
        },       
      ],
    },
  ]);

  return (
    <>
      {/* <Routes>
  <Route index element={<Home/>} />
  <Route path="/OrderForm" element={<OrderForm/>} />  
  <Route path="/Hampers" element={<Hampers/>} />
  <Route path="/Reviews" element={<Reviews/>} />
  <Route path="/About-Us" element={<Aboutus/>} />
  <Route path="/About-Us/:Section" element={<Aboutus/>} />
  <Route path="/Products" element={<ProductsPage/>} />
  <Route path="/Products/:Selection" element={<ProductsPage/>} />
  <Route path="/Hampers/:ThemeName" element={<OrderForm/>} />
  <Route path="/Hampers/:ThemeName/:hamperName" element={<OrderForm/>} />
</Routes> */}

      {/* <footer>Match Hampers: Matchhampers Ltd., <br/> Registered Office: 5 Tanner's Yard, London Rd, Bagshot GU19 5HD.
Registered in England and Wales: Company Number: 13238757 &copy; Copyright 2022</footer> */}

      <div className="App">
        <RouterProvider router={router} />
      </div>

      {/* <MhFooter /> */}
    </>
  );
}

export default App;
