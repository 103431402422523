import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";
import * as FaIcons from "react-icons/fa";
import Navigation from "./Navigation";
import { useLocation } from "react-router-dom";

function Navbar() {
  const location = useLocation();
  const [checked, setchecked] = useState(false);

  const toggleMenu = () => {
    setchecked((prev) => !prev);
  };


  useEffect(() => {
    setchecked(false);    
  }, [location]);

    return (
        <>
          <div className={styles.sidebar}>            
            <div className={styles.menu__icon} onClick={toggleMenu}>
              <div
                className={
                  `${styles.line} ` +
                  `${styles.line__1} ` +
                  `${checked ? styles.checked : ""} `  
                  // + `${(isHome == false)  ? styles.dark : ""} `  
                }
              ></div>
              <div
                className={
                  `${styles.line} ` +
                  `${styles.line__2} ` +
                  `${checked ? styles.checked : ""} `
                  //  + `${(isHome == false)  ? styles.dark : ""} `  
                }
              ></div>
              <div
                className={
                  `${styles.line} ` +
                  `${styles.line__3} ` +
                  `${checked ? styles.checked : ""} ` 
                  // + `${(isHome == false)  ? styles.dark : ""} `  
                }
              ></div>
            </div>
    
            
    
            {/* <div className={styles.year}>
            <p>2023</p>
          </div> */}
          </div>
          <Navigation checked={checked} />
        </>
      );
    }
    
    export default Navbar;