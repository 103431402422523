import React from "react";
import * as GoIcons from "react-icons/go";
import PersonalisationInfo from "./PersonalisationInfo";
import classes from "./PersonalisationTextInfo.module.css";
function PersonalisationTwoDesignInfo({ Location, btnClass }) {
  const title = `${Location} Personalisation`;
  const description = (
    <div>
      <label className={classes.label}>Design</label>
      <div>
        {" "}
        For products that are personalised with embroidery, there are set
        designs to choose from depending on what the product is. Simply click
        ‘Choose’ to view the designs and confirm your choice. The colours are
        pre-set for these designs.
      </div>
      <label className={classes.label}>Design Colour</label>
      <div>
        You can choose from a list of fourteen colours for your design. You can
        choose a different colour for your design to your text. However, a
        colour MUST be selected if you’ve written in the design section.
      </div>
    </div>
  );
  const example = (
    <div>
      For this personalisation, ‘robot’ was chosen from the design selection.
    </div>
  );

  const exception = (
    <div>
      For the ‘birth block’ design (available on particular products only), you
      must select the design and then use the text box to include all the
      information such as name, date, time and weight.
    </div>
  );
  return (
    <PersonalisationInfo
      Title={title}
      Descirption={description}
      Image="/Images/Personalisation/6.jpg"
      Example={example}
      Exception={exception}
      buttonClass={btnClass}
      ExceptionImage="/Images/Personalisation/5.jpg"
    />
  );
}

export default PersonalisationTwoDesignInfo;
