import React from 'react'
import styles from './SetHamperWrapper.module.css'
import SetHamperCard from './SetHamperCard'
function SetHamperWrapper(props) {
  return (
    <div className={styles.wrapper}>
        {props.HamperList.map((x, i) => (
            
            <SetHamperCard
              key={i}
              Description={x.Description}
              Img={x.Img}
              Name={x.Name}
              Price={x.Price}
              HamperName={x.HamperName}
              UrlName={x.URLName}
              HamperSelectedCarouselHandler={props.HamperSelectedCarouselHandler}
              ThemeColour={props.ThemeColour}
              Products={x.Products}
            />
          ))}
    </div>
  )
}

export default SetHamperWrapper