import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropdown from "../UI/Dropdown";
import InfoTooltip from "../UI/InfoTooltip";
import Textbox from "../UI/Textbox";
import ErrorDisplay from "../UI/ErrorDisplay";
import classes from "./Container.module.css";
import ContainerPersonalisation from "./ContainerPersonalisation";
import mhPlaceHolder from "../UI/MHLogo6-noMeta.svg";
import ContainerImage from "./ContainerImage";
import ProductInformation from "../UI/Modal/ProductInformation";

function Container(props) {
  // const [selectedContainer, setSelectedContainer] = useState({});

  const [containerImage, setContainerImage] = useState(mhPlaceHolder);

  const findColours = () => {
    if (!props.data.Container.ContainerName) {
      return [];
    }
    const itemSelected = props.ContainerList.find(
      (x) => x.Name === props.data.Container.ContainerName
    );

    if (!itemSelected) {
      return [];
    }

    return itemSelected.Colours;
  };

  const GetInformation = () => {
    if (!props.data.Container.ContainerName) {
      return {
        Name: "",
        Description: "",
        Materials: "",
        WashingInstructions: "",
        Warnings: [],
      };
    }
    const itemSelected = props.ContainerList.find(
      (x) => x.Name === props.data.Container.ContainerName
    );

    if (!itemSelected) {
      return {
        Name: "",
        Description: "",
        Materials: "",
        WashingInstructions: "",
        Warnings: [],
      };
    }

    return {
      Name: itemSelected.Name,
      Description: itemSelected.Description,
      Materials: itemSelected.Materials,
      WashingInstructions: itemSelected.WashingInstructions,
      Warnings: itemSelected.Warnings,
    };
  };

  const HandleSelectionChanged = (Name, value) => (e) => {
    // const itemSelected = props.ContainerList.find(
    //   (x) => x.Name === props.data.Container.ContainerName
    // );

    // const type = itemSelected?.PersonalistionOption;
    // if (type != undefined) {
    //   switch (type.Type) {
    //     case 1:
    //       props.updatePropertyValue("Container", "Colour", "");
    //       props.updatePropertyValue("Container", "ContainerText", "");
    //       props.updatePropertyValue("Container", "TextColour", "");
    //       props.updatePropertyValue("Container", "Design", "");
    //       props.updatePropertyValue("Container", "DesignColour", "");
    //       break;
    //     case 2:
    //       props.updatePropertyValue("Container", "ContainerText", "");
    //       props.updatePropertyValue("Container", "TextColour", "");
    //       props.updatePropertyValue("Container", "Design", "");
    //       break;
    //   }
    // }

    props.handleChangeSpread(Name, value)(e);
  };

  return (
    <div className="ordFromItems">
      <div className="ordFromHeader">Container</div>
      <h5>
        Please choose the colour and personalisation for the container (where
        applicable).
      </h5>

      <div className={classes.containerWrapper_Details}>
        <div className={classes.Container__Grid}>
          <div className={classes.Container__GridItem}>
            <label>Name:</label>
            {props.ContainerNamePreSet}
            {props.ContainerNamePreSet ? (
              <label>{props.data.Container.ContainerName}</label>
            ) : (
              <select
                id="ContainerNameList"
                name="ContianerName"
                value={props.data.Container.ContainerName}
                onChange={HandleSelectionChanged("Container", "ContainerName")}
              >
                <option value={""} disabled>
                  Select your container
                </option>
                {props.ContainerList?.map((x, i) => (
                  <option value={x.Name} key={`ctnName-` + i}>
                    {x.Name}
                    {" (£" + x.Price + ")"}
                  </option>
                ))}
              </select>
            )}

            {props.data.Container.ContainerName.length > 0 ? (
              <ProductInformation {...GetInformation()} />
            ) : (
              <></>
            )}

            <ErrorDisplay
              errors={props.errors}
              Name={"ContainerContainerName"}
            />
          </div>
          <div className={classes.Container__GridItem}>
            <label>Colour:</label>
            {findColours().length > 1?(
            <select
              id="ColourList"
              name="ContainerColour"
              value={props.data.Container.ContainerColour}
              onChange={props.handleChangeSpread("Container", "ContainerColour")}
            >
              <option value={""} disabled>
                Select your colour
              </option>
              {findColours().map((x, i) => (
                <option value={x} key={i}>
                  {x}
                </option>
              ))}
            </select>):(
              <label className={classes.labelBox}>{findColours()[0]}</label>
            )}
            <ErrorDisplay errors={props.errors} Name={"ContainerContainerColour"} />
          </div>
        </div>

        <ContainerPersonalisation
          PersonalistionOption={props.selectedContainer?.PersonalistionOption}
          data={props.data}
          FindColours={findColours()}
          handleChangeSpread={props.handleChangeSpread}
          handleChangeSpreadDirect={props.handleChangeSpreadDirect}
          errors={props.errors}
          TextColours={[
            "red",
            "orange",
            "yellow",
            "green",
            "light blue",
            "dark blue",
            "purple",
            "baby pink",
            "hot pink",
            "grey",
            "silver",
            "gold",
            "white",
            "black",
          ]}
          updatePropertyValue={props.updatePropertyValue}
        />
      </div>
      <ContainerImage selectedContainer={props.selectedContainer} />
    </div>
  );
}

export default Container;
