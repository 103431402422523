import React, {useEffect, useState} from 'react';
import mhPlaceHolder from "../MHLogo6-noMeta.svg";

function ImageProvider(props) {

    const [displayImage,setDisplayImage] = useState(mhPlaceHolder);
    const [altText,setAltText] = useState("Match Hampers logo");

    useEffect(() => {

        if (props.Img){
            setDisplayImage(props.Img);
        }else{
            setDisplayImage(mhPlaceHolder);
        }
        
        },[props.Img])
    
        useEffect(() => {

        if (props.AltText){
            setAltText(props.AltText);
        }else{
            setAltText("Match Hampers logo");
        }
        
        },[props.AltText])


  return (
    <img src={displayImage} alt={altText}/>
  )
}

export default ImageProvider