import React from "react";
import classes from "./Details.module.css";

function DeliveryDetails(props) {
  return (
    <div className="ordFormDeliveryDetails">
      <div className="ordFromHeader">Delivery Details</div>
      <h5>Recipient’s Details (person receiving the hamper)</h5>
      <div className={classes.detailGrid__three}>
        <div>
          <label>Full Name:</label>
          <input
            name="FullName"
            type="text"
            value={props.data.DeliveryDetails.FullName}
            onChange={props.handleChangeSpread("DeliveryDetails", "FullName")}
          ></input>
          {props.errors.DeliveryDetailsFullName && (
            <p className="error">{props.errors.DeliveryDetailsFullName}</p>
          )}
        </div>
        <div>
          <label>Telephone No:</label>
          <input
            name="TelephoneNumber"
            type="text"
            value={props.data.DeliveryDetails.TelephoneNumber}
            onChange={props.handleChangeSpread(
              "DeliveryDetails",
              "TelephoneNumber"
            )}
          ></input>
        </div>
        <div>
          <label>Email Address:</label>
          <input
            name="Email"
            type="text"
            value={props.data.DeliveryDetails.Email}
            onChange={props.handleChangeSpread("DeliveryDetails", "Email")}
          ></input>
        </div>
        </div>
        <div className={classes.detailGrid__address}>
          <div className={classes.detailGrid__address__HomeAddress}>
          <label>Home Address:</label>
          <textarea
            name="HomeAddress"
            type="text"
            value={props.data.DeliveryDetails.HomeAddress}
            onChange={props.handleChangeSpread(
              "DeliveryDetails",
              "HomeAddress"
            )}
            cols="40"
            rows="5"
          ></textarea>
          {props.errors.DeliveryDetailsHomeAddress && (
            <p className="error">{props.errors.DeliveryDetailsHomeAddress}</p>
          )}
          </div>
        
        <div className={classes.detailGrid__address__postcode}>
          <label>Postcode:</label>
          <input
            name="Postcode"
            type="text"
            value={props.data.DeliveryDetails.Postcode}
            onChange={props.handleChangeSpread("DeliveryDetails", "Postcode")}
          ></input>
          {props.errors.DeliveryDetailsPostcode && (
            <p className="error">{props.errors.DeliveryDetailsPostcode}</p>
          )}
        </div>
      </div>      
    </div>
  );
}

export default DeliveryDetails;
