import React, { useState } from "react";
import styles from "./SetHamperCard.module.css";

import * as FaIcons from "react-icons/fa";
import * as GoIcons from "react-icons/go";
import cardStyle from "../Shared/FlipCard/FlipCard.module.css";

function SetHamperCard(props) {
  const HamperSelected = (e) => {
    e.preventDefault();
    props.HamperSelectedCarouselHandler(props.UrlName);
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const flip = (e) => {
    e.preventDefault();
    setIsFlipped((prev) => !prev);
  };

  const whatsIncluded = (products, container) => {
    let contents = "Includes: " + container;

    if (products == undefined || products.length <= 0) {
      contents = contents + ".";
    } else {
      let productsentince = products
        .reduce((prev, current, i) => {
          return (
            prev + current.Name + (i === products.length - 2 ? " and " : ", ")
          );
        }, "")
        .slice(0, -2);
      contents = contents + ", " + productsentince;
    }

    return contents;
  };

  return (
    <div className={cardStyle.card}>
      <div
        className={
          `${cardStyle.card__inner} ` +
          (isFlipped ? `${cardStyle.is__flipped}` : "")
        }
      >
        <div
          className={
            `${cardStyle.card__face} ` + `${cardStyle.card__face__front}`
          }
          style={{ backgroundColor: props.ThemeColour }}
        >
          <div className={styles.hamper__highlight}>
            <img src={props.Img} className={styles.product__image}></img>
            <div className={styles.title}>{props.HamperName}</div>
            <div className={styles.price}>£{props.Price}</div>
            <div className={styles.actions}>
              {/* <button className={styles.info} onClick={() => flip}> */}
              <FaIcons.FaInfoCircle
                onClick={flip}
                size={"3rem"}
                style={{ color: "#fff", cursor: "pointer" }}
              />
              {/* </button> */}
              <button
                className={styles.select__button}
                onClick={HamperSelected}
              >
                Select
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            `${cardStyle.card__face} ` + `${cardStyle.card__face__back}`
          }
          style={{ backgroundColor: props.ThemeColour }}
        >
          <div className={styles.info__header}>
            <FaIcons.FaInfoCircle
              onClick={flip}
              className={styles.info__header__infoIcon}
            />
            <div>
              <h2 className={styles.info__header__title}>{props.HamperName}</h2>
              <div className={styles.info__header__price}>£{props.Price}</div>
            </div>

            <GoIcons.GoX
              onClick={flip}
              className={styles.info__header__closeIcon}
            />
          </div>
          <div className={styles.info__description__contents}>
            <p className={styles.info__description}>{props.Description}</p>
            <p className={styles.info__contents}>
              {whatsIncluded(props.Products, props.Name)}
            </p>
            <button className={styles.select__button} onClick={HamperSelected}>
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetHamperCard;
