import React from 'react'
import styles from './Header.module.css'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <Link to="/">
    <div className={styles.header}>
    </div>
    </Link>
  )
}

export default Header