import React from 'react'
import classes from './Personalisation.module.css';

function PersonalisationExampleItem({Description, Img}) {
  return (
    <div className={classes.PersonalisationExampleItem}>
        {Description}
        <div className={classes.Example__Item__ImgWrapper}>
        <img className={classes.Example__Item__Image} src={Img}/>
        </div>
    </div>
  )
}

export default PersonalisationExampleItem