import React, { useEffect, useState } from "react";
import Textbox from "../../UI/Textbox";
import Dropdown from "../../UI/Dropdown";
import ClearPersonalisation from "../ClearPersonalisation";
import SelectModal from "../../UI/Modal/SelectModal";
import ItemSelection from "../../UI/Modal/Selection/ItemSelection";
import classes from './Wedding.module.css';
import ErrorDisplay from '../../UI/ErrorDisplay'

function OrderItemPersonalisationWeddingAct(props) {
  const [showModal, setShowModal] = useState(false);

  const hideModalHandler = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  const showModalHandler = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleItemSelction = (i, name) => {
    setShowModal(false);

    props.handleChangeSpreadNestedDirect("Items", props.Number, name, i);
  };

  const handleChosenSelection = (items) => {
    setShowModal(false);
   if (items.length == 2){
      handleItemSelction(items[0].name, "BrideGroomImage1");
      handleItemSelction(items[1].name, "BrideGroomImage2");      
   }
   
  }

  // useEffect(() => {
  //  // wedding
  //  props.handleChangeSpreadNestedDirect(
  //    "Items",
  //    props.Number,
  //    "ChildsName",
  //    ""
  //  );
  //  props.handleChangeSpreadNestedDirect(
  //    "Items",
  //    props.Number,
  //    "CouplesName",
  //    ""
  //  );
  //  props.handleChangeSpreadNestedDirect(
  //    "Items",
  //    props.Number,
  //    "DateOfWedding",
  //    ""
  //  );
  //  props.handleChangeSpreadNestedDirect(
  //    "Items",
  //    props.Number,
  //    "BrideGroomImage1",
  //    ""
  //  );
  //  props.handleChangeSpreadNestedDirect(
  //    "Items",
  //    props.Number,
  //    "BrideGroomImage2",
  //    ""
  //  );
  // },[props.data.Items[props.Number].ItemName])

  const clearPersonalisation = (e) => {
    e.preventDefault();
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "ChildsName",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "CouplesName",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "DateOfWedding",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "BrideGroomImage1",
      ""
    );
    props.handleChangeSpreadNestedDirect(
      "Items",
      props.Number,
      "BrideGroomImage2",
      ""
    );
  };

  return (
    <div>
      <Textbox
        LabelValue="Child's Name:"
        type="text"
        Name="ChildsName"
        Value={props.data.Items[props.Number].ChildsName}
        ValueChanged={props.handleChange("Items", props.Number, "ChildsName")}
        errors={props.errors}
        errorName={"ChildsName"}
        Number={props.Number}
        //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
      />
      <Textbox
        LabelValue="Couple's Names:"
        type="text"
        Name="CouplesName"
        Value={props.data.Items[props.Number].CouplesName}
        ValueChanged={props.handleChange("Items", props.Number, "CouplesName")}
        errors={props.errors}
        errorName={"CouplesName"}
        Number={props.Number}
        //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
      />
      <Textbox
        LabelValue="Date of Wedding:"
        type="date"
        Name="DateOfWedding"
        Value={props.data.Items[props.Number].DateOfWedding}
        ValueChanged={props.handleChange(
          "Items",
          props.Number,
          "DateOfWedding"
        )}
        errors={props.errors}
        errorName={"DateOfWedding"}
        Number={props.Number}
        //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
      />
      <button onClick={showModalHandler}>Choose</button>
      <SelectModal Show={showModal} HideModal={hideModalHandler}>
        <ItemSelection
          items={props.ItemDesignOptions}
          HandleItemSelction={handleItemSelction}
          Max={['Bride/Groom 1','Bride/Groom 2']}
          handleChosenSelection={handleChosenSelection}
          Instuction={<div>Please select <b>two</b> designs. Then, click `next' to review your selection. To undo, click the design you wish to remove and select the new one.</div> } 
        />
      </SelectModal>
         <label className={classes.BrideGroom__Label}>Bride/Groom Image: {props.data.Items[props.Number].BrideGroomImage1}</label>
         <ErrorDisplay errors={props.errors} Name= {"BrideGroomImage1"} Number={props.Number} />
         <label className={classes.BrideGroom__Label}>Bride/Groom Image: {props.data.Items[props.Number].BrideGroomImage2}</label>     
      <ClearPersonalisation
        clearPersonalisationHandler={clearPersonalisation}
      />
    </div>
  );
}

export default OrderItemPersonalisationWeddingAct;
