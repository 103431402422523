import React from "react";
import styles from "./Personalisation.module.css";
function Personalisation() {
  return (
    <div className={styles.personalisation}>
      <div className={styles.personalisation__wrapper}>
        <div className={styles.header}>What is personalisation?</div>
        <div className={styles.personalistation__what}>
          Personalisation means tailoring a product to individuals or groups. It
          is defined as "the action of designing or producing something to meet
          someone's individual requirements", (Oxford Dictionary). Not only can
          you make your hamper bespoke by choosing the specific products for
          your loved one, but you can also add personalised designs. You can
          choose to add text, a design, both, or none to the applicable products.          
        </div>
        <img
          className={styles.header__image}
          src="/Images/content/Personalisation/bottle-soc.png"
          alt="bottle soc"
        ></img>
        <div className={styles.how__header}>How does it work?</div>
        <div className={styles.how__text}>
          When ordering your hamper, you'll see which products can and cannot be
          personalised. You will have the option to request text and/or designs
          in a range of fourteen colours (red, orange, yellow, green, light
          blue, dark blue, purple, hot pink, light pink, grey, white, black,
          silver, or gold). When your order has been submitted, we can send you
          designs to choose from.
        </div>
        <img
          className={styles.how__image}
          src="/Images/content/Personalisation/aprons.png"
          alt="personalised aprons"
        ></img>
        <div className={styles.choice__header}>Embroidery or vinyl?</div>
        <div className={styles.choice__text}>
          We have a range of machines to personalise our products with vinyl or
          embroidery thread. Depending on which machine we use, depends on your
          chosen products and requested designs. Some products lend themselves
          better to be embroidered, and some designs are better to be heat
          pressed. Take this storage trug for example. "TOYS" has been heat
          pressed with vinyl and fits a large area over the trug. If we were to
          embroider, we would have to use a smaller hoop to fit in the trug and
          therefore a smaller design. We will make the decision and advise which
          option is best for you.
        </div>
        <img
          className={styles.choice_image}
          src="/Images/content/Personalisation/trug.png"
          alt="personalised aprons"
        ></img>
        <div className={styles.think__about__header}>
          What to think about?
        </div>
        <div className={styles.think__about__text}>
          Please check the spellings, capital letters, and punctuation before
          you submit your order. We cannot use any saying or image that has been
          trademarked due to copyright reasons. Some people may not want their
          name on a product. In this case, consider using an initial, initials,
          surname, or a design instead. Be conscious of the size of the product
          and the space for personalisation. Some products lend themselves to a
          more simple, clear message or design, while other products look good
          with intricate designs.
        </div>
        <img
          className={styles.think__about__image}
          src="/Images/content/Personalisation/Baby-Comforter.png"
          alt="Baby Comforter"
        ></img>
        <div className={styles.text__design__header}>Text or design?</div>
        <div className={styles.text__design__text}>
          For this baby fleece blanket, you would have "Oliver" in the text
          section and "aeroplane" in the design section. As well as selecting
          "dark blue" as the colour for both parts. You can choose a different
          colour for your design to your text if you prefer. Some designs have
          multiple colours which are changed by our discretion. We have a range
          of fonts to use, so if you have a preferred font we can try to
          accommodate this (but it's not guaranteed).
        </div>
        <img
          className={styles.text__design__image}
          src="/Images/content/Personalisation/BabyBlanket.png"
          alt="Baby Blanket with the name 'oliver' embrodered on it."
        ></img>
      </div>
      <div className={styles.examples__header}>Examples</div>
      <div className={styles.example__wrapper}>
        <div className={styles.example__one}>
          <img
            className={styles.example__image}
            src="/Images/content/Personalisation/Nanys-blanket.png"
            alt="Blanket with `Nanny's Knitting on'"
          ></img>

          <ul className={styles.example__text}>
            <li>Text: "NANNY'S KNITTING BLANKET"</li>
            <li>Text colour: hot pink</li>
            <li>(no design)</li>
          </ul>
        </div>
        <div className={styles.example__two}>
          <img
            className={styles.example__image}
            src="/Images/content/Personalisation/Koala-Jumper.png"
            alt="Jumper with a Koala face on it"
          ></img>
          <ul className={styles.example__text}>
            <li>Design: "koala face"</li>
            <li>Design colour: white</li>
            <li>(no text)</li>
          </ul>
        </div>
        <div className={styles.example__three}>
          <img
            className={styles.example__image}
            src="/Images/content/Personalisation/baby-towel.png"
            alt="Baby's towel with hood"
          ></img>
          <ul className={styles.example__text}>
            <li>
            Text: "Sunny"
            </li>
            <li>
            Text colour: orange 
            </li>
            <li>
            Design: "sun"
            </li>
            <li>
              Design colour: yellow
            </li>
          </ul>
        </div>
      </div>
      </div>
  );
}

export default Personalisation;
