import React, { useState, useEffect } from "react";
import axios from "axios";
import HoldingPageActions from "../UI/HoldingPageActions";
import MhLogo from "../UI/MHlogo";
import classes from "./Aboutus.module.css";

import { useParams,Link } from "react-router-dom";
import Personalisation from "./Personalisation";


function Aboutus() {

  const { Section} = useParams();
  const [aboutUs, setAboutUs] = useState([]);
  useEffect(() => {
    const fetchAboutUs = async () => {
      const ctrns = await axios("/JSON/Brochure.json", {
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setAboutUs(ctrns.data);
    };
    fetchAboutUs();
  }, []);


  const isBrochure = () => {
    if (Section != undefined && Section.toLowerCase() == "personalisation"){
        return false;
    }
    return true;
  }

  return (
    <>
      <div className={classes.Hampers__Header}>
        <MhLogo />
        <HoldingPageActions Name={"Aboutus"} />
      </div>
      <div className={classes.AboutUsLinks}>
        <button className={isBrochure()?`${classes.LinkSelected}`:`${classes.Link}`}>
        <Link to="/About-Us" style={isBrochure()? {color:"white"}:{color:"black"}}>
         About Us
        </Link>
        </button>
        <button className={!isBrochure()?`${classes.LinkSelected}`:`${classes.Link}`}>
        <Link to="/About-Us/Personalisation" style={!isBrochure()? {color:"white"}:{color:"black"}}>
        Personalisation
        </Link>
        </button>
      </div>
    {isBrochure()?(
      <>
    
      <div className={classes.AboutUsWrapper}>

        {aboutUs.map((x,i) => (
            <img src={x} key={i} alt={`review image` + i} />
        ))}

      </div>
      </>):(
        <Personalisation />
      )}
    </>
  );
}

export default Aboutus;
