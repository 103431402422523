import React from "react";
import TextArea from "../UI/TextArea";

function GiftWrapHolder(props) {
  return (
    <div className="ordFormGiftWrap">
      <div className="ordFromHeader">Gift Wrap</div>
      <div className="ordGiftwrap">
        <img
          src="/Images/Giftwrap/Ribbon.jpg"
          alt="ribbon example"
        />

        <div className="frmItem">
          <TextArea
            LabelValue="Message on Ribbon (maximum 30 characters):"
            type="text"
            Name="Ribbon"
            Value={props.data.Ribbon}
            ValueChanged={props.handleChange("Ribbon")}
            //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
            Columns="40"
            Rows="2"
            errors={props.errors}
            errorName={"Ribbon"}
            maxLength="30"
          />                 
        </div>
      </div>
      <div className="ordGiftwrap">
        <img
          src="/Images/Giftwrap/Gift-Tag.jpg"
          alt="Gift tag example"
        />

        <div className="frmItem">
        <TextArea
            LabelValue="Message on Gift Tag (maximum 30 characters):"
            type="text"
            Name="GiftTag"
            Value={props.data.GiftTag}
            ValueChanged={props.handleChange("GiftTag")}
            //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
            Columns="40"
            Rows="2"
            errors={props.errors}
            errorName={"GiftTag"}
            maxLength="30"
          />               
        </div>
      </div>
      <div className="ordGiftwrap">
        <img
          src="/Images/Giftwrap/Sticker.jpg"
          alt="Sticker example"
        />

        <div className="frmItem">
        <TextArea
            LabelValue="Message on Sticker (maximum 50 characters):"
            type="text"
            Name="Sticker"
            Value={props.data.Sticker}
            ValueChanged={props.handleChange("Sticker")}
            //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
            Columns="40"
            Rows="2"
            errors={props.errors}
            errorName={"Sticker"}
            maxLength="30"
          />               
         
        </div>
      </div>
    </div>
  );
}

export default GiftWrapHolder;
