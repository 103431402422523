import React from "react";
import { Link } from "react-router-dom";
import classes from "./MhFooter.module.css";

function MhFooter() {
  return (
    <footer>
      <div className={classes.footer__wrapper}>
        <div className={classes.mh__contactus}>
          <h2>Contact Us</h2>
          <div className={classes.contactus__details}>
            <div>
              <Link to="mailto:contact@matchhampers.com">
                contact@matchhampers.com
              </Link>
            </div>
            <div>
              <Link to="https://facebook.com/MatchHampers">
                facebook.com/MatchHampers
              </Link>
            </div>
            <div>
              <Link to="https://instagram.com/matchhampers">
                instagram.com/matchhampers
              </Link>
            </div>
          </div>
        </div>
        {/* explore */}
        <div className={classes.mh__explore}>
          <h2>Explore</h2>
          <div className={classes.mh__explore__details}>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <Link to="/About-Us">About Us</Link>
            </div>
            <div>
              <Link to="/Products">Products</Link>
            </div>
            <div>
              <Link to="/Hampers">Hampers</Link>
            </div>
            <div>
              <Link to="/Reviews">Reviews</Link>
            </div>
            <div>
              <Link to="/Documents/Terms-And-Conditions.pdf" target="_blank">
                Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
        {/* address */}

        <div className={classes.mh__address}>
          <h2>Match Hampers</h2>
          <div>
            Matchhampers Ltd.
            <div className={classes.mh__address__details}>
              Registered Office:
              <div>5 Tanners Yard, London Road,</div>
              <div> Bagshot,</div>
              <div> GU19 5HD</div>
              <div>Registered in England and Wales</div>
              <div>Company Number: 13238757</div>
            </div>
          </div>
        </div>
        <div className={classes.mh__logo}>
          <img src="/Images/content/mh-logo-TM-edited-white.png"></img>
        </div>
        <div className={classes.mh__tagline}>
          -&nbsp;stay connected with your loved ones' celebrations&nbsp;-
        </div>
      </div>
    </footer>
  );
}

export default MhFooter;
