import React, { useEffect,useState } from 'react'
import classes from './ItemImage.module.css'
import mhPlaceHolder from "../UI/MHLogo6-noMeta.svg";

function ItemImage(props) {
    const [itemImage,setItemImage] = useState(mhPlaceHolder);

    useEffect(() => {

        const i = props.GetProductImage(props.ItemName);
        if (i){
            setItemImage(i);
        }
        
   
        
        },[props.ItemName])


  return (
    <img src={itemImage} className={`${classes.itemImage} ${props.className}`}/>
  )
}

export default ItemImage