import React from 'react'
import ErrorDisplay from './ErrorDisplay';
import classes from  './TextArea.module.css';

function TextArea(props) {
  return (
    <div className={props.className}>
    <label style={{marginLeft:"2em"}}>{props.LabelValue}
    {props.Info?(props.Info):<></>}
     {/* cols="40" rows="5"></textarea>  */}
    <textarea name={props.Name} type={props.Type} value={props.Value} onChange={props.ValueChanged} cols={props.Columns} rows={props.Rows} maxLength={props.maxLength} ></textarea> 
    </label>
    <ErrorDisplay errors={props.errors} Name= {props.errorName} Number={props.Number} />
    </div>
  )
}

export default TextArea