import React, {useState,useEffect} from 'react'
import classes from './Reviews.module.css';
import axios from "axios";
import HoldingPageActions from '../UI/HoldingPageActions';
import MhLogo from '../UI/MHlogo';

function Reviews() {


   const [reviews,setReviews] = useState([]);
    useEffect(() => {
        const fetchReviews = async () => {
          const ctrns = await axios("/JSON/Reviews.json", {
            responseType: "json",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          setReviews(ctrns.data);
        };
        fetchReviews();
      }, []);


  return (
    <>
      
    <div className={classes.ReviewWrapper}>
          <img src="/Images/Reviews/1.jpg" alt="ReviewHeader"/>
          <img src="/Images/Reviews/2.jpg" alt="Description information - Client vs Recipient"/>
          <img src="/Images/Reviews/3.jpg" alt="Leave a review"/>

          {
            reviews.map((x,i) => (
              <img src={x} alt={`review image` + {i}} key={i} />
            ))
          }

    </div>
    </>
  )
}

export default Reviews