import React from "react";

import * as FaIcons from "react-icons/fa";
import classes from "./ThemeItem.module.css";
import ImageProvider from "../../UI/Images/ImageProvider";
import { Link } from "react-router-dom";
function ThemeItem(props) {
  const showPdf = () => {
    return props.PDFLink != null ? true : false;
  };

  const themeStyle = () => {
    let theme = "theme__Order__link";

    if (showPdf() == false) {
      theme = theme + "__center";
    }

    return theme;
  };

  return (
    <div
      className={classes.theme__item}
      style={{ backgroundColor: props.Colour }}
    >
      <div className={classes.theme__heading}>
        <h3>{props.Name}</h3>
      </div>
      <div className={classes.theme__image}>
        <ImageProvider Img={props.Img} AltText={props.Name} />
      </div>
      <div className={classes.theme__description}>
        <p>{props.Description}</p>
      </div>
      
      <div className={classes.theme__action}>
        <div>
          {props.IsProduct ? (
            <>
              {showPdf() == true ? (
                <div className={classes.personalisation__link}>
                  <Link to={props.PDFLink} target="_blank">
                    <button className={classes.product__button}>
                      Products
                    </button>
                  </Link>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {showPdf() == true ? (
                <div className={classes.personalisation__link}>
                  <Link to={props.PDFLink} target="_blank">
                    <button className={classes.product__button}>
                      {props.Name} Brochure
                    </button>
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>

        <div className={classes.personalisation__link}>
          <Link to={"/Hampers/" + props.OrderLink}>
            <button className={classes.product__button}>
              {props.Name} Hampers
            </button>
          </Link>
        </div>
      </div>
      
    </div>

    
  );
}

export default ThemeItem;
