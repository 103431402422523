import React, { useEffect, useState } from "react";
import "./OrderForm.css";
import OrderItem from "./Items/OrderItem";
import { UseForm } from "./UseForm";
import InfoTooltip from "./UI/InfoTooltip";
import UseOrderFormValidations from "./UseOrderFormValidations";
import axios from "axios";
import Container from "./Containers/Container";
import { useParams } from "react-router-dom";
import SetHamperSelector from "./SetHamperSelector";
import ItemList from "./Items/ItemList";
import GiftWrapHolder from "./GiftWrapOptions/GiftWrapHolder";
import ContactDetails from "./PersonDetails/ContactDetails";
import DeliveryDetails from "./PersonDetails/DeliveryDetails";
import AdditionalComments from "./PersonDetails/AdditionalComments";
import OrderConsent from "./PersonDetails/OrderConsent";
import InitialValues from "./InitialValues";
import { UseProducts } from "./Items/UseProducts";
import { UseContainers } from "./UseContainers";
import { UseHamper } from "./UseHamper";
import Placeholder from "./UI/Placeholder";
import MhLogo from './UI/MHlogo';
import {UseThemes} from './UseThemes';
import ThemeHeader from "./Hampers/Themes/ThemeOrder/ThemeHeader";
import styles from './OrderForm.module.css';

export default function OrderForm() {
  const [ordersubmitted, setOrderSubmitted] = useState({});
  const [SeedData, setSeedData] = useState([]);
  const { ThemeName, hamperName } = useParams();
  const initialValues = InitialValues();
  const [showContent, setShowContent] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState({});
 

  const successMessage =
    "Thank you for submitting your order to Match Hampers. We will be in touch soon via email for review and payment. Please check all your inboxes including junk/spam folders for an email from Match Hampers. Once payment has been received, we will create and dispatch your hamper within 10-14 days. Your hamper will aim to be delivered within 2-3 working days.";

  const { validations,setValidationSelectedContainer} = UseOrderFormValidations();

  const IsSetTheme = () => {
    return ThemeName == null || ThemeName == defaultName ? null : ThemeName;
  };




  const defaultName = "CreateYourOwn";

  const {
    data,
    handleChange,
    handleSubmit,
    errors,
    validate,
    ValidateProperty,
    handleChangeSpread,
    handleChangeSpreadNested,
    handleCheckedChange,
    AddKeydItem,
    RemoveKeydItem,
    ClearKeydItem,
    handleChangeSpreadDirect,
    handleChangeDirect,
    handleChangeSpreadNestedDirect,
    updatePropertyValueHandler
  } = UseForm({
    validations: validations,
    initialValue: initialValues,
    onSubmit: () => submitForm(),
  });


  const {GetContainers} = UseContainers();
  const {HamperList} = UseHamper( {
    handleChangeDirect: handleChangeDirect,
    data : data,
    handleChangeSpreadDirect : handleChangeSpreadDirect,
    setShowContent : setShowContent,
    ClearKeydItem: ClearKeydItem,
    defaultName : defaultName
  });
  const { productList, GetProductItems, GetProductSize, GetProductColour,GetPersonalisationOption,GetDesignOptions,GetProductImage,GetOptionName,GroupProductsInCategories,GetTextColour,GetInformation} = UseProducts({
    handleChangeDirect: handleChangeDirect,    
    handleChangeSpreadDirect : handleChangeSpreadDirect,
    setShowContent : setShowContent,
    ClearKeydItem: ClearKeydItem,
     IsSetTheme : IsSetTheme,
     defaultName : defaultName,
     HamperList : HamperList,
     data : data
    });


  const submitForm = () => {

    var items = data["Items"].map(({PersonalisationOption, Colours, Size, ...item}) => item);

    //items = items.map(({Colours, ...item}) => item);


    data["Items"] = items;

    const response = axios.post("/order.php", data);
    response
      .then((response) => setOrderSubmitted(response.data))
      .catch((err) => {
        const ej = { State: "Failed", Message: "Network error" };
        setOrderSubmitted(ej);
      });
  };

  const AddItemRow = (e) => {
    e.preventDefault();
    AddKeydItem("Items", {
      ItemName: "",
      ItemSize: "",
      ItemColour: "",      
      ItemTextColour: "",
      ItemDesign: ""      
    });
  };

  const DeleteItemRow = (e, i) => {
    e.preventDefault();
    RemoveKeydItem("Items", i);
  };

 const {GetThemeColour,themeColour, GetSubTitle, GetDescription,GetThemeImage} = UseThemes({ThemeName:ThemeName});



  useEffect(() => {
    const containerSelected = GetContainers().find(
      (x) => x.Name === data.Container.ContainerName
    );

    updatePropertyValueHandler("Container", "ContainerColour", "");
    updatePropertyValueHandler("Container", "ContainerText", "");
    updatePropertyValueHandler("Container", "TextColour", "");
    updatePropertyValueHandler("Container", "Design", "");
    updatePropertyValueHandler("Container", "DesignColour", "");


   

    if (containerSelected != undefined){
      if (containerSelected.Colours?.length == 1)
      {
        updatePropertyValueHandler("Container", "ContainerColour", containerSelected.Colours[0]);
      }      
    }


   setSelectedContainer(containerSelected);

      if (containerSelected?.PersonalistionOption?.Type != undefined){
        setValidationSelectedContainer(containerSelected.PersonalistionOption.Type)
      }else{
        setValidationSelectedContainer(1);
      }

  }, [data.Container.ContainerName]);
 

  return !ordersubmitted["State"] ? (
    <div className="ordFrom">
    
    <div className={styles.hamper_selection}>
    <div className={styles.Theme__Header}>
    <ThemeHeader  Name={ThemeName == defaultName? "Create Your Own" : GetSubTitle(ThemeName)}
                  Colour ={themeColour}
                  Description={GetDescription(ThemeName)}
                  Img={GetThemeImage(ThemeName)}    
                  IsProduct={ThemeName == defaultName}
                />
    </div>
    </div>
      
   
      <form>
        <div className="ordFrmDetails">
          {IsSetTheme() != null ? (
            <SetHamperSelector
              HamperList={HamperList}
              data={data}
              handleChangeSpread={handleChangeSpread}
              ThemeLink={ThemeName}  
              ThemeColour={themeColour}            
            />
          ) : null}
          {showContent === true ? (
            <>
            <Placeholder>
            <Container
                data={data}
                handleChangeSpread={handleChangeSpread}
                errors={errors}
                ContainerList={GetContainers()}
                ContainerNamePreSet={IsSetTheme() == null? false: true}
                selectedContainer={selectedContainer}
                handleChangeSpreadDirect={handleChangeSpreadDirect}
                updatePropertyValue={updatePropertyValueHandler}
              />
            </Placeholder>
              <Placeholder>

              
              <ItemList
                data={data}
                handleChangeSpreadNested={handleChangeSpreadNested}
                errors={errors}
                AddItemRow={AddItemRow}
                DeleteItemRow={DeleteItemRow}
                GetProductItems={GetProductItems}
                GetProductSize={GetProductSize}
                GetProductColour={GetProductColour}
                GetDesignOptions={GetDesignOptions}
                handleChangeSpreadNestedDirect={handleChangeSpreadNestedDirect}
                GetPersonalisationOption={GetPersonalisationOption}
                GetProductImage={GetProductImage}
                GetOptionName={GetOptionName}
                GroupProductsInCategories = {GroupProductsInCategories}     
                GetTextColour={GetTextColour}
                GetInformation={GetInformation}           
              />
              </Placeholder>
              <Placeholder>
              <GiftWrapHolder
                data={data}
                handleChange={handleChange}
                errors={errors}
              />
               </Placeholder>
               <Placeholder>

              <ContactDetails
                data={data}
                handleChangeSpread={handleChangeSpread}
                errors={errors}
              />
               </Placeholder>
               <Placeholder>

              <DeliveryDetails
                data={data}
                handleChangeSpread={handleChangeSpread}
                errors={errors}
                />
                </Placeholder>

                <Placeholder>
                  <AdditionalComments data={data} handleChange={handleChange}
                  errors={errors}
                  />
                </Placeholder>
              
              <OrderConsent
                data={data}
                handleCheckedChange={handleCheckedChange}
                errors={errors}
                handleSubmit={handleSubmit}
              />
            </>
          ) : null}
        </div>
      </form>
    </div>
  ) : ordersubmitted["State"] == "Success" ? (
    <div className="Success">
      <img
        className="ordThankyou"
        src="/Images/thankyou.jpg"
        alt="Thank you image"
      ></img>
      {successMessage}
      <br />
      Order No: {ordersubmitted["OrderId"]}
    </div>
  ) : (
    <div className="error">
      There was a problem with placing your order: {ordersubmitted["Message"]}
      <br />
      Please refresh the page and try again.
    </div>
  );
}
