import React, {useEffect, useState} from 'react'
import styles from './Aboutpage.module.css'
import {useLocation } from 'react-router-dom'
import AboutNavigation from './AboutNavigation';
import AboutSection from './Sections/About/AboutSection';
import Personalisation from './Sections/Personalisation/Personalisation';
import GiftWrap from './Sections/GiftWrap/GiftWrap';
import EcoMessage from './Sections/Eco/EcoMessage';
import Team from './Sections/Team/Team';

function Aboutpage() {

  const location = useLocation();

  const [selectedHeading, setSelectedHeading] = useState("about-us");

  useEffect(() => {
  
  if (location.pathname != null){
      const paths = location.pathname.split('/');
  

      if (paths.length == 2){

        setSelectedHeading(paths[1].toLowerCase());

          
      }
      else{
        
        setSelectedHeading(paths[2].toLowerCase());
      }
  
      

  }
  }
  ,[location])

  return (
    <div className={styles.container}>
    <AboutNavigation heading={selectedHeading}/>
    {selectedHeading == 'about-us' &&
    <AboutSection/>
    }
    {selectedHeading == "personalisation" && 
    <Personalisation/>
    }
    {selectedHeading == "gift-wrap" && 
    <GiftWrap/>
    }
    {selectedHeading == "eco-message" && 
    <EcoMessage/>
    }
    {selectedHeading == "the-team" && 
    <Team/>
    }
    </div>
  )
}

export default Aboutpage;