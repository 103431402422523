import React from "react";
import * as GoIcons from "react-icons/go";
import PersonalisationInfo from "./PersonalisationInfo";
import classes from "./PersonalisationTextInfo.module.css";

function PersonalisationOneTextInfo({ Location, btnClass }) {
  const title = `${Location} Personalisation`;

  const description = (
    <div>
      <label className={classes.label}>Text</label>
      <div>
        {" "}
        Use this section to type the wording you would like on the product.
        Please check the spellings, capital letters, punctuation, etc. before
        you submit the order form. We cannot use any saying that has been
        trademarked due to copyright reasons. We do have a range of fonts to
        use, so if you have a preferred font we can try to accommodate this (but
        it’s not guaranteed). Once you’ve typed your text, use the extra space
        in the box to let us know your desired font. There is limited area for personalisation on some products.
      </div>
      <label className={classes.label}>Text Colour</label>
      <div>
        You can choose from a drop down list of fourteen colours for your text.
        You can choose a different colour for your text to your design. However,
        a colour MUST be selected if you’ve written in the text section.
      </div>
    </div>
  );
  const example = (
    <div>
      For this personalisation ‘Gillian’ would be written in the Text section
      and then ‘black’ chosen from the Text Colour drop down list.
    </div>
  );

  return (
    <PersonalisationInfo
      Title={title}
      Descirption={description}
      Image="/Images/Personalisation/1.jpg"
      Example={example}
      buttonClass={btnClass}
    />
  );
}

export default PersonalisationOneTextInfo;
