import React from "react";
import ErrorDisplay from "./ErrorDisplay";
function Dropdown(props) {
  return (
    <div className={props.className}>    
      <label>{props.LabelValue}</label>      
      <select                
        name={props.Name}
        value={props.Value}
        onChange={props.ValueChanged}
      >
        <option value={""} disabled>
          {props.DefaultText}
          
        </option>
        {props.Options?.map((x, i) => (
          <option value={x} key={i} style={{backgroundImage: "url(http://localhost:3000/mh-logo-TM-edited.png)"}}>            
            {x}
          </option>
        ))}
      </select>
      <ErrorDisplay errors={props.errors} Name= {props.errorName} Number={props.Number}/>
    </div>
  );
}

export default Dropdown;
