import React from "react";
import styles from "./AboutSection.module.css";
import WhereItStarted from "./WhereItStarted/WhereItStarted";
import ValuesSection from "./ValuesSection";
import HowItWorks from "./HowItWorks";
function AboutSection() {
  return (
    <div className={styles.container}>
      <div className={styles.about__us}>
        <div className={styles.header}>
          <h2>Who we are</h2>
        </div>
        <p className={styles.who__are__we}>
          Match Hampers is a gift service delivering meaningful and bespoke
          hampers across Great Britain. We cater for every occasion to make your
          friends and family feel loved and cherished all year round. Match
          Hampers is a one-stop shop for all your gift buying needs throughout
          the year. You can order individual gifts and bespoke hampers through
          our website. You can have gifts sourced, personalised, wrapped and
          delivered without lifting a finger.
        </p>
        <img
          className={styles.who__are__we_image}
          src="/Images/content/About/jobag.png"
        ></img>
        <div className={styles.who__are__we__paragraph__two}>
          <p className={styles.who__are__we__paragraph__two__a}>
            We are building on the tradition of giving a hamper as a gift. When
            you hear the word "hamper", you may be picturing a traditional
            wicker basket filled with food and drink. However, to us, a hamper
            comprises of a container with handpicked items inside.
          </p>
          <p className={styles.who__are__we__paragraph__two__b}>
            Our products are reusable, meaningful and personalised to your loved
            ones and occasions. Many of our products are from independent,
            British businesses. You can make your gifts bespoke by choosing
            exactly which products you give.
          </p>
        </div>
        <img
          className={styles.cheers_image}
          src="/Images/content/About/cheers.png"
        ></img>
    
      </div>
       <WhereItStarted />
       <ValuesSection /> 
     <HowItWorks />
      <div className={styles.end}></div>
    </div>
  );
}

export default AboutSection;
