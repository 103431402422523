import React, { useState } from "react";

export function UseForm(options) {
  const [data, setData] = useState(options.initialValue || {});
  const [errors, setErrors] = useState({});

  const handleChange = (key) => (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleChangeDirect = (key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCheckedChange = (key) => (e) => {
    const value = e.target.checked;
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleChangeSpread = (key, property) => (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [key]: {
        ...data[key], // spread the keyed item
        [property]: value,
      },
    });
    return value;
  };

  const handleChangeSpreadDirect = (key, property, value) => {
    
    setData({
      ...data,
      [key]: {
        ...data[key], // spread the keyed item
        [property]: value,
      },
    });
    return value;
  };

  const updatePropertyValueHandler = (key,property,value) => {

    setData((prevState) => ({
      ...prevState,
      [key]:{
        ...prevState[key],
             
          [property]:value
        
      }
      
    }))
  }

  const handleChangeSpreadNested = (key, index, property) => (e) => {
    const value = e.target.value;

    const tempItem = [...data[key]];

    tempItem[index] = {
      ...tempItem[index],
      [property]: value,
    };

    setData({ ...data, [key]: tempItem });

    // setData({
    //   ...data,
    //   [key]: {
    //     ...data[key][index], // spread the keyed item
    //     [property]: value
    //   },
    // });
  };
  const handleChangeSpreadNestedDirect = (key, index, property, value) => {
    
    setData((prevState) => ({
      ...prevState,
      [key]: prevState[key]?.map((x, i) =>
        i === index ? { ...x, [property]: value } : x
      ),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      if (options?.onSubmit) {
        options.onSubmit();
      }
    }
  };

  const AddKeydItem = (key, item) => {
    const keyedItemArray = data[key];
    keyedItemArray.push(item);

    setData({ ...data, [key]: keyedItemArray });
    // setData((prevState) => ({
    //    ...prevState,
    //    [key]:  prevState[key].push(item) }));
  };

  const RemoveKeydItem = (key, item) => {
    const keyedItemArray = data[key];

    if (item > -1) {
      keyedItemArray.splice(item, 1);
    }

    setData({ ...data, [key]: keyedItemArray });

    // setData((prevState) => ({
    //   ...prevState,
    //   [key]:  keyedItemArray ,
    // }));
    // setData((prevState) => ({
    //   ...prevState,
    //   [key]:  {

    //   ...prevState[key].splice(item,1)
    //   } ,
    // }));
  };

  const ClearKeydItem = (key, item) => {
    // setData({ ...data, [key]: null });
    setData((prevState) => ({ ...prevState, [key]: item }));
  };

  const validate = () => {
    const validations = options?.validations;

    if (validations) {
      let valid = true;

      const newErrors = {};

      for (const key in validations) {
        const value = data[key];
        const itemValidation = validations[key];

        if (itemValidation !== null) {
          // required check.
          if (itemValidation.required?.value && !value) {
            valid = false;
            newErrors[key] = itemValidation.required?.message;
           
          }

          // regex check.
          if (
            itemValidation.pattern?.value &&
            !RegExp(itemValidation.pattern?.value).test(value)
          ) {
            valid = false;
            newErrors[key] = itemValidation.pattern?.message;
          
          }

          // custom check.
          if (
            itemValidation.custom?.isValid &&
            !itemValidation.custom.isValid(value)
          ) {
            valid = false;
            newErrors[key] = itemValidation.custom?.message;
            
          }

          // child items
          if (itemValidation.propertyValidation) {
            valid = propertyValidation(itemValidation, value, newErrors, key,valid);
          }

          if (itemValidation.Personalisation) {
            valid = PersonalisationValidations(
              itemValidation,
              value,
              newErrors,
              key,valid
            );
          }

          if (itemValidation.PersonalisationRecord){

            // needs to loop around the items like the collection below does. 
            valid = PersonalisationRecord(itemValidation,value,newErrors,key,valid);
          }

          if (itemValidation.Collection) {
            let childValid = valid;

            if (Array.isArray(value)) {
              let errorArray = [];

              for (let v = 0; v < value.length; v++) {
                let errorItem = {};
                errorItem["Id"] = v;

                let tmpKeys = [];
                Object.keys(itemValidation.Collection).forEach((proKey) => {
                  var x = value[v];

                  const propValue = x[proKey];
                  var x = itemValidation.Collection[proKey].isValid(propValue);
                  if (x == false) {
                    childValid = false;

                    errorItem[`${proKey}`] =
                      itemValidation.Collection[proKey].message;
                     
                    errorArray.push(errorItem);
                  }
                });
              }

              if (errorArray.length > 0) {
                newErrors[`${key}`] = errorArray;
              }

              valid = childValid;
            }
          }
        }
      }

      if (!valid) {
        setErrors(newErrors);
        return valid;
      }

      setErrors({});
      return valid;
    }
  };

  // Ability to Validate a property in situ - prior to submitting the overall form.
  const ValidateProperty = (key) => {
    const validations = options?.validations;

    const itemValidation = validations[key];
    let valid = true;
    const newErrors = {};
    const value = data[key];
    if (itemValidation !== null) {
      // required field check.
      if (itemValidation?.required?.value && !value) {
        valid = false;
        newErrors[key] = itemValidation.required?.message;
       
      }

      // regex check.
      if (
        itemValidation?.pattern?.value &&
        !RegExp(itemValidation.pattern?.value).test(value)
      ) {
        valid = false;
        newErrors[key] = itemValidation.pattern?.message;
        
      }

      // custom check.
      if (
        itemValidation?.custom?.isValid &&
        !itemValidation.custom.isValid(value)
      ) {
        valid = false;
        newErrors[key] = itemValidation.custom?.message;
       
      }
    }

    let e = errors;

    if (!valid) {
      e[key] = newErrors[key];
    } else {
      delete e[key];
    }

    setErrors((prevState) => ({
      ...prevState,
      e,
    }));

    return valid;
  };

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
    validate,
    ValidateProperty,
    handleChangeSpread,
    handleChangeSpreadNested,
    handleCheckedChange,
    AddKeydItem,
    RemoveKeydItem,
    ClearKeydItem,
    handleChangeSpreadDirect,
    handleChangeDirect,
    handleChangeSpreadNestedDirect,
    updatePropertyValueHandler
  };

  function PersonalisationValidations(itemValidation, value, newErrors, key, isValid) {
    const r = itemValidation.Personalisation.isValidEx(value);

    
    let valid =  isValid;

    for (var i = 0; i < r.length; i++) {
      
      if (!r[i].isValid(value)) {
        newErrors[`${key}${r[i].Property}`] = r[i].Message;
      }
    }
    return valid;
  }

  function propertyValidation(itemValidation, value, newErrors, key, isValid) {
    let childValid = isValid;

    Object.keys(itemValidation.propertyValidation).forEach((proKey) => {
      const propValue = value[proKey];
      var x = itemValidation.propertyValidation[proKey].isValid(propValue);
      if (x == false) {
        childValid = false;
        newErrors[`${key}${proKey}`] =
          itemValidation.propertyValidation[proKey].message;
         
      }
    });

    return childValid;
  }



  function PersonalisationRecord(itemValidation,v,newErrors,key, isValid){
    let valid = isValid;

    let errorArray = [];


  
    // Checking that v is a collection. 
    if (Array.isArray(v)){
      // loops around the collection. 
      for (var i = 0; i < v.length; i++) {
        let errorItem = {};
        errorItem["Id"] = i;
        var itm = v[i];
        // gets the validations for that specific item. 
        const validationRules = itemValidation.PersonalisationRecord.Validations(itm)
          

          if (Array.isArray(validationRules)){
            var addItemError = false;
            for (var vr = 0; vr < validationRules.length; vr++) {

              let vItem = validationRules[vr];

              
              if (!vItem.isValid(itm)) {
                
                errorItem[`${vItem.Property}`] = vItem.Message
                addItemError = true;
                
              }
            }
            if (addItemError){
              errorArray.push(errorItem);
            }
            
          }

      }

      if (errorArray.length > 0){
        valid = false;        
        newErrors[`${key}`] = errorArray;
      }
      
    }

    

    return valid;
  }
}
