import React from 'react'
import TextArea from '../UI/TextArea'
import classes from './AdditionalComments.module.css'

function AdditionalComments(props) {
  return (
    <>
     <div className='ordFromHeader'>Additional Comments</div>
     <div className='frmItemRow'>
        <div className='frmItem'>           

            <TextArea
            LabelValue="Please use this box to add any additional comments about your hamper. If necessary, we will respond to these comments by email."
            type="text"
            Name="AdditionalComments"
            Value={props.data.AdditionalComments}
            ValueChanged={props.handleChange("AdditionalComments")}
            //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
            Columns="40"
            Rows="5"
            errors={props.AdditionalComments}
            errorName={"AdditionalComments"}
            maxLength="300"
            className={classes.AdditionalComments__TextArea}
          /> 



        </div>   
        </div>
    </>
  )
}

export default AdditionalComments