import React from "react";
import styles from "./HomePersonalisation.module.css";
import { Link } from "react-router-dom";

function HomePersonalisation() {
  return (
    <div className={styles.PersonalisationSection}>
      <img
        className={styles.backing__photo}
        src="Images/content/hat.png"
        alt="Embroidary on hat"
      />
      <div className={styles.personalisation__content}>
        <h2 className={styles.header}>Personalisation</h2>
        <div className={styles.personalisation__text}>
          <p>
            Many of our products can be personalised with text and/or designs.         
            Adding a personalised design is the ultimate way to give a unique gift.
          </p>
        </div>
        <img
          className={styles.small__image}
          src="Images/content/gillian.png"
          alt="personalised wash bag"
        />
        <div className={styles.personalisation__link}>
          <Link to="/About-Us/Personalisation">
            <button className={styles.product__button}>Read more</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePersonalisation;
