import React from "react";
import styles from "./TimelineItem.module.css";

function TimeLineItem(props) {
  return (
    <div
      className={
        `${styles.timeline__item} ` +
        `${props.islast == true ? styles.timeline__last : ""}`
      }
    >
        <div className={styles.date}>
            {props.date}        
        </div>
        <div className={styles.event}>
            {props.event}
        </div>
    </div>
  );
}

export default TimeLineItem;
