import React, { useEffect, useState } from "react";
import CarouselItem from "./CarouselItem.js";
import classes from "./CarouselWrapper.module.css";
import * as FaIcons from "react-icons/fa";
import { IconContext } from "react-icons";
import {useSwipeable}  from 'react-swipeable'
function CarouselWrapper(props) {
  const [activeIndex, SetActiveIndex] = useState(0);



    const handlers = useSwipeable({
        onSwipedLeft: () => updateActive(activeIndex +1),
        onSwipedRight: () => updateActive(activeIndex -1)
    });


  const changePage = (e,v) => {
    e.preventDefault();
    const pn = Number(v);
    SetActiveIndex(pn);
  };

  const updateActive = (nextIndex) => {
    if (nextIndex < 0) {
      nextIndex = props.HamperList.length - 1;
    } else if (
      nextIndex >= props.HamperList.length
    ) {
      nextIndex = 0;
    }
    SetActiveIndex(nextIndex);
  };

  return (
    <>
    
    <div className={classes.CarouselWrapper} {...handlers}>
      <div
        className={classes.CarouselInner}
        style={{ transform: `translateX(-${activeIndex * 90}vw)`}}
        
      >
        
        {props.HamperList.map((x, i) => (
            
          <CarouselItem
            key={i}
            Description={x.Description}
            Img={x.Img}
            Name={x.Name}
            Price={x.Price}
            HamperName={x.HamperName}
            UrlName={x.URLName}
            HamperSelectedCarouselHandler={props.HamperSelectedCarouselHandler}
          />
        ))}
     
      </div>
      
      { 
      
        
      
       props.HamperList.map((x, i) => {
              return (
               
                <button
                  onClick={(e) => changePage(e,i)}
                  className={`${classes.pageNumber}  ${
                    activeIndex === i ? classes.pageSelected : ""
                  }`}
                  key={i+1}
                >
                  
                </button>
              );
            })}

      <div
        className={classes.prev}
        onClick={() => {
            updateActive(activeIndex - 1);
        }}
      >
        <FaIcons.FaRegArrowAltCircleLeft style={{color:`black`}}
          className={classes.CarouselHodler__Button}
        />
      </div>
      

      <div
        className={classes.next}
        onClick={() => {
            updateActive(activeIndex + 1);
        }}
      >
        <FaIcons.FaRegArrowAltCircleRight style={{color:`black`}}
          className={classes.CarouselHodler__Button}
        />
      </div>
      
    </div>
    </>
  );
}

export default CarouselWrapper;
