import React from 'react'
import styles from './ReviewItem.module.css'

function ReviewItem(props) {
  return (
    <div className={styles.review__item}>
        <h3 className={styles.who}>{props.who} Review</h3>
        <span className={styles.review__text}>{props.review}</span>
    </div>
  )
}

export default ReviewItem