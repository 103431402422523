import axios from "axios";
import React, { useEffect, useState } from "react";
import ThemeItem from "./Themes/ThemeItem";
import classes from "./Themes/Themes.module.css";
import HoldingPageActions from "../UI/HoldingPageActions";
import MhLogo from "../UI/MHlogo";

function Hampers() {
  const [themes, setThemes] = useState([]);
  const _createYourOwn = "/mh-logo-name.png";

  useEffect(() => {
    const getThemes = async () => {
      const _themes = await axios("/JSON/Themes.json", {
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setThemes(_themes.data);
    };

    getThemes();
  }, []);

  return (
    <div className={classes.themes__wrapper}>
      <div className={classes.themes__header}>
        <div className={classes.themes__heading}>
          <h2>Hampers</h2>
        </div>

        <div className={classes.themes__description__paragraph__one}>
          <div>Have a special occasion coming up? </div>
          <div>Looking for a creative birthday present? </div>

          <div>Wanting to give the perfect wedding gift?</div>
          <div> Trying to find a unique new home hamper? </div>
        </div>
        <div className={classes.themes__image}>
          <img
            src="/Images/content/GiftWrap/wrapped-trug.png"
            alt="Trug wrapped"
          />
        </div>
        <p className={classes.themes__description__paragraph__two}>
          Browse our set hampers, read the descriptions, and select the one
          you'd like. Alternatively, choose to "Create Your Own" and select your
          chosen container and item(s). Either route, make the products bespoke,
          add a gift wrap message, and fill in the delivery details.
          Personalisation and gift wrap are included with your hamper.
        </p>
      </div>

      <div className={classes.Theme__Holder}>
        {Array.isArray(themes)
          ? themes.map((t, i) => (
              <ThemeItem
                Name={t.Name}
                key={i}
                Img={t.Img}
                OrderLink={t.OrderLink}
                PDFLink={t.PDFLink}
                Colour={t.Colour}
                Description={t.Description}
                IsProduct={t.IsProduct}
                TextAppend={t.TextAppend}                
              />
            ))
          : null}
        <h1 className={classes.more}>MORE HAMPERS COMING SOON</h1>
      </div>
    </div>
  );
}

export default Hampers;
