import React, { useEffect, useState } from "react";
import Dropdown from "../UI/Dropdown";
import Textbox from "../UI/Textbox";
import classes from "./Container.module.css";
import ContainerOptionOne from "./ContainerOptionOne";
import ContainerOptionTwo from "./ContainerOptionTwo";


const renderPersonalisation = (p, i, h, k, d) => {






  switch (p.Type) {
    case "Text":
      return (
        <Textbox
          LabelValue={p.Label}
          ValueChanged={h(k, p.Property)}
          Name={p.Property}
          Key={i}
          Value={d[k][p.Property]}
        />
      );
    case "DropDown":
      return (
        <Dropdown
          LabelValue={p.Label}
          Options={p.Options}
          DefaultText={p.DefaultText}
          ValueChanged={h(k, p.Property)}
          Name={p.Property}
          Value={d[k][p.Property]}
        />
      );
    default:
      return <></>;
  }
};

const renderPersonalisationOption = (props) => {
  
const personalisationSentence = "Personalisation can be left blank if preferred.";

  if (props.PersonalistionOption?.Type != undefined) {
    switch (props.PersonalistionOption.Type) {
      case 1:
        return (         
          
          <ContainerOptionOne
            handleChangeSpread={props.handleChangeSpread}
            data={props.data}
            FindColours={props.FindColours}
            TextColours={props.TextColours}
            errors={props.errors}
            updatePropertyValue={props.updatePropertyValue}
          />
        
        );
      case 2:
        return (
        
          <ContainerOptionTwo
            handleChangeSpread={props.handleChangeSpread}
            handleChangeSpreadDirect={props.handleChangeSpreadDirect}
            data={props.data}
            FindColours={props.FindColours}
            Options={props.PersonalistionOption.Options}
            TextColours={props.TextColours}
            errors={props.errors}
            updatePropertyValue={props.updatePropertyValue}
          />
        
        );
      default:
        return <></>;
    }
  }
  return <></>;
};

function ContainerPersonalisation(props) {
 
  return (
    <>
     
      {renderPersonalisationOption(props)}
    </>
  );
}

export default ContainerPersonalisation;
