import React, { useEffect, useState } from "react";
import CarouselItem from "./UI/CarouselItem";
import Carousel from "./UI/Carousel";
import "./SetHamperSelector.css";
import { useNavigate } from "react-router-dom";
import CarouselWrapper from "./UI/Carousel/CarouselWrapper";
import SetHamperWrapper from "./OrderForm/SetHamperWrapper";

function SetHamperSelector(props) {
  const navigate = useNavigate();
  const [hamperSelected, setHamperSelected] = useState(false);
  const [selectedHamperImage, setSelectedHamperImage] =
    useState("/mh-logo-name.png");

  useEffect(() => {
    if (props.data.Hamper.HamperName != "") {
      const imgurlt = props.HamperList?.find(
        (x) => x.HamperName == props.data.Hamper.HamperName
      ).Img;
      setSelectedHamperImage(imgurlt);
    }
  }, [props.data.Hamper.HamperName]);

  const HamperSelectedDropDownHandler = (e) => {
    const ev = e.target.value;
    const hamperName = props.HamperList.find((x) => x.HamperName == ev);

    navigate(`/Hampers/${props.ThemeLink}/${hamperName.URLName}`, {
      replace: true,
    });

    setHamperSelected(true);
  };

  const HamperSelectedCarouselHandler = (hamperName) => {
    navigate(`/Hampers/${props.ThemeLink}/${hamperName}`, { replace: true });
    setHamperSelected(true);
  };

  const hamperSelectorStyle = () => {
    let style = "HamperSelectorHolder";
    if (hamperSelected) {
      style += " small";
    }
    return style;
  };

  const showAllHampers = (e) => {
    e.preventDefault();
    navigate(`/Hampers/${props.ThemeLink}/`, { replace: true });
  };

  const getHamperDetails = () => {
    const y = props.HamperList.find(
      (x) => x.HamperName == props.data.Hamper.HamperName
    );

    return y;
  };

  return (
    <div className="orfFrm__HamperSelector">
      {props.data.Hamper.HamperName === "" ? (
        <>
          {/* <div className="frmItemRow">
            <div className="frmItem"> */}
              

              <SetHamperWrapper
                HamperList={props.HamperList}
                ThemeColour={props.ThemeColour}
                HamperSelectedCarouselHandler={HamperSelectedCarouselHandler}
              />
            {/* </div>
          </div> */}
        </>
      ) : (
        <div className="hamper__selected">
          <img src={selectedHamperImage} className="selectedHamperImg" />

          <div className="hamper__selected__details">
            <div className="hamper__selected__title">
              {props.data.Hamper.HamperName}
            </div>
            <div className="hamper__selected__description">
              {getHamperDetails()?.Description}
            </div>
            <div className="hamper__selected__price">
              £{getHamperDetails()?.Price}
            </div>
          </div>
          <div className="allHampers">
            <button onClick={showAllHampers}>Show All Hampers</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SetHamperSelector;
