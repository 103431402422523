import React from 'react'
import styles from './WhereItStarted.module.css'
import TimeLineItem from './TimeLineItem'

function WhereItStarted() {

  const events = [
    {
      "event":"The name \"Match Hampers\" was thought of and the concept of the containers decided.",
      "date":"30th November 2020"
    },
    {
      "event":"We bought our first machine and started personalising.",
      "date":"December 2020"
    },
    {
      "event":"We registered the business with Companies House and had our first order for a retirement hamper. ",
      "date":"March 2021"
    },
    {
      "event":"We moved Match Hampers out of our garage and built our first dedicated space." ,
      "date":"July 2021"
    },
    {
      "event":"We wrote our first business plan with six containers and sixteen items on our product list." ,
      "date":"November 2021"
    },
    {
      "event":"Our first ever market! We had a stall at the Royal Oak in Watnall." ,
      "date":"December 2021"
    },
    {
      "event":"Our first trade shows! We visited Spring Fair and Top Drawer where we met Eco Bath, Bottle Soc and Egg Nogg." ,
      "date":"February 2022"
    },
    {
      "event":"We launched the first version of our website in time for Mother's Day orders." ,
      "date":"March 2022"
    },
    {
      "event":"We took part in the student projects with Nottingham Trent University." ,
      "date":"July 2022"
    },
    {
      "event":"A busy Christmas with orders and markets: Mama Meet's Festive Market and Kimberley Christmas Market." ,
      "date":"November 2022"
    },
    {
      "event":"We sent 60 hampers internationally for a corporate client." ,
      "date":"December 2022"
    },
    {
      "event":"We moved Match Hampers head quarters to accommodate our expanding product list and collection of machinery." ,
      "date":"March 2023"
    },
    {
      "event":"Spring Markets! Ruddington Village Market, Mama Meet Easter Market, Pavilion Garden's Spring Spectacular, and Checkley Craft Fair." ,
      "date":"April 2023"
    },
    {
      "event":"We took part in the student projects with Nottingham Trent University again." ,
      "date":"July 2023"
    },
  ]


  return (
    <div className={styles.where__it__started}>
      <h2>Our journey so far...</h2>     
      <div className={styles.timeline__wrapper}>
      
      {events.map((e,i) => (
        <TimeLineItem key={i} event={e.event}  date={e.date} islast={i == events.length - 1}/>
      ))}
      </div>
      
    </div>
  )
}

export default WhereItStarted