import React, {useEffect, useState} from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import MhFooter from "../UI/Footer/MhFooter";
import Header from "./Header/Header";
import styles from './Layout.module.css'

function Layout() {

  const location = useLocation();

  const [showHeader,setShowHeader] = useState(false);
  useEffect(() => {
    
    window.scrollTo(0, 0);
    if (location.pathname.length > 1){
      setShowHeader(true);
    }
    else{
      setShowHeader(false);
    }

  }, [location]);

  return (
    <>
      <Navbar />
      {showHeader? <Header/>: null}
      <main>
        <Outlet />
      </main>
      <MhFooter />
      <div className={styles.copywright}>Matchhampers Ltd. &copy; 2023 </div>
    </>
  );
}

export default Layout;
