import React from "react";

function MhLogo() {
  return (
    <div>
      <a
        href={"/"}
        style={{ textDecoration: "none", color: "white" }}
      >

<img className="mh-logo"
        src="/mh-logo-TM-edited.png"
        alt="MatchHampers logo"
      />

      </a>
    </div>
  );
}

export default MhLogo;
