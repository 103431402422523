import React, { useEffect, useState } from "react";
import axios from "axios";
import mhPlaceHolder from "../UI/MHLogo6-noMeta.svg";
export function UseProducts(props) {
  const [productList, setProductList] = useState([]);
  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const ctrns = await axios("/JSON/Items.json", {
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setProductList(ctrns.data);
    };
    fetchProducts();
  }, []);


  // useEffect(() => {
  //   const result = productList.reduce((r, a) => {
  //     r[a.Category] = r[a.Category] || [];
  //     r[a.Category].push(a);
  //     return r;
  //   }, []);

  //   if (!result) {
  //   setProductCategories([]);  
  //   }
  //   else{
  //     setProductCategories(result);
  //   }
    


  // },[productList]);

  const selectedHamper = () => {
    const sh = props.HamperList.find(
      (x) => x.HamperName === props.data.Hamper.HamperName
    );
    
    return sh;
  };

  const GetProductItems = () => {
    // let items = productList.map((i) => i.Name);
    // return Array.isArray(items) ? items : [];

    return [...productList];
  };

  const GetProductSize = (item) => {
    let selectedItem = productList.find((i) => i.Name === item);
    if (selectedItem != undefined) {
      return selectedItem.Sizes;
    }
    return [];
  };
  const GetProductColour = (item) => {
    let selectedItem = productList.find((i) => i.Name === item);
    if (selectedItem != undefined) {
      return selectedItem.Colours;
    }
    return [];
  };

  const GetPersonalisationOption = (item) => {
    let selectedItem = productList.find((i) => i.Name === item);
    if (selectedItem != undefined) {
      return selectedItem.PersonalistionOption;
    }
    return {};
  };

  const GetDesignOptions = (name) => {
    let selectedItem = productList.find((i) => i.Name === name);
    return selectedItem.ContainerDesign;
  };

  const GetProductImage = (name) => {
    let selectedItem = productList.find((i) => i.Name === name);
    if (selectedItem != undefined) {
      if (selectedItem.Img != undefined) {
        return selectedItem.Img;
      }
    }
    return "";
  };

  const GetOptionName = (name) => {
    let selectedItem = productList.find((i) => i.Name === name);
    if (selectedItem != undefined) {
      if (selectedItem.OptionName != undefined) {
        return selectedItem.OptionName;
      }
    }
    return "Colour";
  };

  const GroupProductsInCategories = () => {    
    const result = productList.reduce((r, a) => {
      r[a.Category] = r[a.Category] || [];
      r[a.Category].push(a);
      return r;
    }, []);

    if (!result) {
      return [];
    }

   


   return Object.entries(result);

   


  };

  const GetTextColour = (name) => {
    let selectedItem = productList.find((i) => i.Name === name);
    if (selectedItem != undefined) {
      if (selectedItem.TextColours != undefined) {
        return selectedItem.TextColours;
      }
    }
    return [
      "red",
      "orange",
      "yellow",
      "green",
      "light blue",
      "dark blue",
      "purple",
      "baby pink",
      "hot pink",
      "grey",
      "silver",
      "gold",
      "white",
      "black",
    ];
  }


  const GetInformation = (name) => {

   
    const itemSelected = productList.find((i) => i.Name === name);
  
    if (!itemSelected) {
      return {
        Name:'',      
        Description: '',
        Materials:'',
        WashingInstructions:'',
        Warnings:[]
      };
    }
  
    return  {
      Name:name,    
      Description: itemSelected.Description,
      Materials:itemSelected.Materials,
      WashingInstructions: itemSelected.WashingInstructions,
      Warnings:itemSelected.Warnings
    };
  };


  useEffect(() => {
    if (props.IsSetTheme()) {
      const itemSelected = selectedHamper();

      

      if (itemSelected != null) {
        props.ClearKeydItem("Items", []);

        let items = itemSelected.Products?.map((x) => ({
          ItemName: x.Name,
          ItemSize: "",
          ItemColour: "",          
          setItem: true,
          ItemTextColour: "",
          ItemDesignColour: "",
          ItemDesign: "",
          Size: GetProductSize(x.Name),
          Colours: GetProductColour(x.Name),
          PersonalisationOption: GetPersonalisationOption(x.Name),
        }));

        if (items != undefined) {
          props.handleChangeDirect("Items", items);
        }

        props.handleChangeDirect("Container", {
          ContainerName: itemSelected.Name,
          ContainerColour: "",          
          ContainerText: "",
          TextColour: "",
          Design: "",
          DesignColour: "",
        });

        // setContainers([itemSelected]);
        props.setShowContent(true);
      }
    }
  }, [props.data.Hamper.HamperName]);

  return {
    productList,
    GetProductItems,
    GetProductSize,
    GetProductColour,
    GetPersonalisationOption,
    GetDesignOptions,
    GetProductImage,
    GetOptionName,
    GroupProductsInCategories,
    GetTextColour,
    GetInformation    
  };
}
