import React from "react";
import Placeholder from '../UI/Placeholder';
import classes from './OrderConsent.module.css';
function OrderConsent(props) {

    const submitError =
    "Your order has not been submitted. Missing/incomplete field(s) - please review before submitting.";

  return (
    <>
    <Placeholder>
      <div className="ordFormConsent">
        <div className="ordFromHeader">Consent</div>
        <div className="frmItem">
          <input
            name="TC"
            type="checkbox"
            value={props.data.TC}
            onChange={props.handleCheckedChange("TC")}
          ></input>
          <label>
            I agree to the{" "}
            <a target="_blank" href="/Documents/Terms-And-Conditions.pdf">
              `Terms and Conditions`
            </a>
            .{" "}
          </label>
          {props.errors.TC && <p className="error">{props.errors.TC}</p>}
        </div>
        <div className="frmItem">
          <input
            name="Confirmation"
            type="checkbox"
            value={props.data.Confirmation}
            onChange={props.handleCheckedChange("Confirmation")}
          ></input>
          <label>
            I confirm that the details submitted on this order form are correct
            and understand that it is my responsibility to check the sizes, spellings,
            capital letters, punctuation, colours, etc. By agreeing to this, I
            understand that returns on personalised goods are not accepted.
          </label>
          {props.errors.Confirmation && (
            <p className="error">{props.errors.Confirmation}</p>
          )}
        </div>
        <div className="frmItem">
          <input
            name="Payment"
            type="checkbox"
            value={props.data.Payment}
            onChange={props.handleCheckedChange("Payment")}
          ></input>
          <label>
          I understand that payment will not be taken at this stage. I agree to confirm and pay for my order via email and will check all inboxes including spam/junk folders for an email from Match Hampers.
          </label>
          {props.errors.Payment && <p className="error">{props.errors.Payment}</p>}
        </div>
    
        
      </div>
      </Placeholder>
      <br></br>
      <span>
          Once payment has been received, we will create and
          dispatch your hamper within 10-14 working days. Your hamper will aim to be delivered in two to three working days (please be mindful of the current postal strikes).
        </span>
        <br></br> <br></br>
        <button onClick={props.handleSubmit} className={classes.submitButton}>Submit Order</button>
        {Object.keys(props.errors).length > 0 && (
          <p className="error">{submitError}</p>
        )}
      <div className="ordFormNextStep">
        
        <h4>Thank you for choosing Match Hampers.</h4>
      </div>
      </>
  );
}

export default OrderConsent;
