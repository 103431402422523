import React from "react";
import styles from "./ValuesSection.module.css";

function ValuesSection() {
  return (
    <div className={styles.valuesSection__wrapper}>
      <h2 className={styles.header}>Values & Vision</h2>
      <h3 className={styles.statement}>
        "We are meaningful, professional, and honest."
      </h3>
      <div className={styles.paragraph__one}>
        Match Hampers started with the idea of the container being part of the
        gift itself and wanting to gift a collection of products rather than one
        thing. We also knew that personalisation was a key part to making the
        hampers bespoke and personal to your loved ones. We have built upon the
        traditional hampers and offer a broad range of gifts for every recipient
        and every occasion.
      </div>
      <img
        className={styles.paragraph__one__image}
        src="/Images/content/About/blanket.png"
        alt="Blanket"
      />
      <div className={styles.paragraph__two}>
        We are passionate about people receiving meaningful gifts with products
        they can actually use and enjoy. We are proud to support independent
        British companies by having their products in our hampers. We are
        building strong relationships with each business owner so that we can
        continue to offer you the best. We will continue to research more
        occasions and release more set hampers for you.
      </div>
      <img
        className={styles.paragraph__two__image}
        src="/Images/content/About/dungarees.png"
        alt="Blanket"
      />
      <div className={styles.paragraph__three}>
        Not only can we make your hampers, but we can take care of all your
        gifts throughout the year. Never miss a birthday again! We can discuss
        who you're buying for and suggest appropriate gifts, then leave it up to
        us. We will source the right products, add personalised designs, wrap
        the gifts and arrange delivery. Your gifts can be delivered straight to
        your loved ones, or to yourself. When your parcels arrive, simple take
        the gifts out the box! Avoid forgetting an occasion, avoid tackling with
        the selotape, and avoid post office queues. You'll feel proud of the
        gifts your loved ones are receiving from you and you'll save valuable
        time. We are passionate about you staying connected with your loved
        ones' celebrations. Contact us today to book an appointment.
      </div>
      <img
        className={styles.paragraph__three__image}
        src="/Images/content/bee.png"
        alt="Bee products"
      />
    </div>
  );
}

export default ValuesSection;
