import React, { useEffect, useState } from "react";
import HoldingPageActions from "../UI/HoldingPageActions";
import MhLogo from "../UI/MHlogo";
import classes from "./ProductsPage.module.css";
import { UseContainers } from "../UseContainers";
import ProductItem from "./ProductItem";
import { useParams, Link } from "react-router-dom";

import ProductItemWrapper from "./ProductItemWrapper";

function ProductsPage() {
  const _containers = "containers";
  const _items = "items";

  const { Selection } = useParams();

  const { GetContainers } = UseContainers();

  const IsSelected = (sectionName) => {
    if (sectionName == "") {
      if (Selection == undefined) {
        return true;
      }
    }

    if (Selection != undefined && Selection.toLowerCase() == sectionName) {
      return true;
    }

    return false;
  };

  const shouldShow = (sectionName) => {
    if (Selection == undefined) {
      return true;
    }

    if (Selection.toLowerCase() != sectionName) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={classes.Container__Items}>
        <label className={classes.instructionLabel}>
          Whatever the occasion, we’ve got you covered! Browse our extensive
          range of meaningful and useful products, many of which can be
          personalised or are available in multiple colours. <br />
          Decide upon a container and items for your recipient. Then, head over
          to our <a href="/Hampers">Hampers</a> page to order.{" "}
        </label>

        <div className={classes.ProductLinks}>
          <button
            className={
              IsSelected("") ? `${classes.LinkSelected}` : `${classes.Link}`
            }
          >
            <Link
              to="/Products"
              style={IsSelected("") ? { color: "white" } : { color: "black" }}
            >
              All
            </Link>
          </button>
          <button
            className={
              IsSelected(_containers)
                ? `${classes.LinkSelected}`
                : `${classes.Link}`
            }
          >
            <Link
              to="/Products/Containers"
              style={
                IsSelected("containers")
                  ? { color: "white" }
                  : { color: "black" }
              }
            >
              Containers
            </Link>
          </button>
          <button
            className={
              IsSelected(_items) ? `${classes.LinkSelected}` : `${classes.Link}`
            }
          >
            <Link
              to="/Products/Items"
              style={
                IsSelected("items") ? { color: "white" } : { color: "black" }
              }
            >
              Items
            </Link>
          </button>
        </div>

        {shouldShow("containers") ? (
          <>
            <h1>Containers</h1>
            <div className={classes.ProductGrid}>
              {GetContainers().map((x, i) => (
                <ProductItem {...x} key={i} />
              ))}
            </div>
          </>
        ) : (
          <></>
        )}

        {shouldShow("items") ? (
          <>
            <h1>Items</h1>
            <div className={classes.ProductGrid}>
              <ProductItemWrapper />
              {/* {productList.map((x,i) => (
                <ProductItem {...x} key={i}/>
            ))} */}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default ProductsPage;
