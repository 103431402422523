import React from 'react'
import classes from './Placeholder.module.css'


function Placeholder(props) {
  return (
    <div className={`${classes.placeholder} ${props.className}`}> 
    {props.children}
    </div>
  )
}

export default Placeholder