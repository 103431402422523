import React, { useEffect, useState } from "react";
import axios from "axios";
import classes from "./ProductItemWrapper.module.css";
import ProductItem from "./ProductItem";
import productClasses from "./ProductsPage.module.css";

function ProductItemWrapper() {
  const [productList, setProductList] = useState([]);
  const [catProductList, setCatProductList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      const ctrns = await axios("/JSON/Items.json", {
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      //setProductList(ctrns.data);

      const result = ctrns.data.reduce((r, a) => {
        r[a.Category] = r[a.Category] || [];
        r[a.Category].push(a);
        return r;
      }, []);

      if (!result) {
        setCatProductList([]);
      }

      const firstItem = Object.keys(result)[0];
      setSelectedTab(firstItem);
      setProductList(result[firstItem]);
      setCatProductList(Object.entries(result));
    };
    fetchProducts();
  }, []);

  const CategorySelected = (name) => {
    const pList = catProductList.find((x) => x[0] == name);
    setSelectedTab(name);
    setProductList(pList[1]);
  };

  const CategorySelectedDropdown = (e) => {
    const name = e.target.value;
    const pList = catProductList.find((x) => x[0] == name);
    setSelectedTab(name);
    setProductList(pList[1]);
  };

  const CatClass = (name) => {
    let catStyle = `${classes.CategorySelection}`;
    if (selectedTab == name) {
      catStyle = catStyle + ` ${classes.Selected}`;
    }

    return catStyle;
  };

  return (
    <div className={classes.ProductItemWrapper}>
      <div className={classes.selectionGroup}>
        {catProductList.map((x, i) => (
          <div
            key={i}
            className={CatClass(x[0])}
            onClick={() => CategorySelected(x[0])}
          >
            {x[0]}
          </div>
        ))}
      </div>
      <div className={classes.selectionGroup__Mobile}>
        <select
          id="CategoryName"
          name="CategoryName"
          value={selectedTab}
          onChange={CategorySelectedDropdown}
        >
          <option value={""} disabled>
            Select your container
          </option>
          {catProductList.map((x, i) => (
            <option value={x[0]} key={`catName-` + i}> 
              {x[0]}
            </option>
          ))}
        </select>
      </div>
      <div className={productClasses.ProductGrid}>
        {productList.map((x, i) => (
          <ProductItem {...x} key={i} />
        ))}
      </div>
    </div>
  );
}

export default ProductItemWrapper;
