import React from "react";
import * as GoIcons from "react-icons/go";
import PersonalisationInfo from "./PersonalisationInfo";
import classes from "./PersonalisationTextInfo.module.css";
function PersonalisationOneDesignInfo({ Location,btnClass }) {
    const title = `${Location} Personalisation`;
  const description = (
    <div>
      <label className={classes.label}>Design</label>
      <div>
        {" "}
        Use this section to detail your desired design and we will try our best
        to accommodate you. We cannot use any saying or image that has been
        trademarked due to copyright reasons. We will advertise some designs
        across our social media platforms for you to use (for example ‘Christmas
        Design 1’). There is limited area for personalisation on some products.
      </div>
      <label className={classes.label}>Design Colour</label>
      <div>
        You can choose from a list of fourteen colours for your design. You can
        choose a different colour for your design to your text. However, a
        colour MUST be selected if you’ve written in the design section.
      </div>
    </div>
  );
  const example = (
    <div>
      For this personalisation, no text was chosen, but ‘wake me up when it’s
      Christmas design’ would be in the design section and then ‘gold’ would be
      chosen from the Design Colour drop down list.
    </div>
  );
  return (
    <PersonalisationInfo
      Title={title}
      Descirption={description}
      Image="/Images/Personalisation/2.jpg"
      Example={example}
      buttonClass={btnClass}
    />
  );
}

export default PersonalisationOneDesignInfo;
