import React, { useEffect, useState } from "react";
import axios from "axios";

export function UseThemes(props) {
  const [themes, setThemes] = useState([]);
  const [themeColour, setThemeColour] = useState("Black");
  
  useEffect(() => {
    const getThemes = async () => {
      const _themes = await axios("/JSON/Themes.json", {
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      setThemes(_themes.data);
    };

    getThemes();
  }, [props.ThemeName]);

  useEffect(() => {
    setThemeColour(GetThemeColour(props.ThemeName));
    GetCustomerInstructions(props.ThemeName);
  }, [themes]);

  const GetThemeColour = (name) => {
    const selectedTheme = themes.find((x) => x.OrderLink == name);

    if (selectedTheme != undefined) {
      return selectedTheme.Colour != undefined ? selectedTheme.Colour : "black";
    }
    return "black";
  };

  const GetSubTitle = (name) => {
    const selectedTheme = themes.find((x) => x.OrderLink == name);

    if (selectedTheme != undefined) {
      return selectedTheme.Subtitle != undefined ? selectedTheme.Subtitle : name;
    }
    return name;
  }

  const GetDescription = (name) => {
    const selectedTheme = themes.find((x) => x.OrderLink == name);

    if (selectedTheme != undefined) {
      return selectedTheme.Description;
    }
    return "";
  };


  const GetThemeImage = (name) => {
    const selectedTheme = themes.find((x) => x.OrderLink == name);

    if (selectedTheme != undefined) {
      return selectedTheme.Img;
    }
    return "";
  };


  const GetCustomerInstructions = (name) => {
    const selectedTheme = themes.find((x) => x.OrderLink == name);

    if (selectedTheme != undefined) {
      if (name != "CreateYourOwn") {
    
      }
    }
  };

  return {
    GetThemeColour,
    themeColour,    
    GetSubTitle,
    GetDescription,
    GetThemeImage
  };
}
