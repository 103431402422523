import React, { useEffect,useState } from 'react'
import mhPlaceHolder from "../UI/MHLogo6-noMeta.svg";
import classes from "./Container.module.css";

function ContainerImage(props) {

    const [containerImage,setContainerImage] = useState(mhPlaceHolder);


useEffect(() => {

if (props.selectedContainer?.Img){
    setContainerImage(props.selectedContainer.Img);
}else{
    setContainerImage(mhPlaceHolder);
}

},[props.selectedContainer])

  return (
    (!props.selectedContainer? <></>:
    <div className={classes.containerImage}>
      <img src={containerImage} width="200px" height="200px" />
    </div>)
  )
}

export default ContainerImage