import React from 'react'

function ErrorDisplay(props) {


    const CheckErrors = (propertyName) => {
        const errorPropertyCollection = props.errors["Items"];
        if (errorPropertyCollection) {
          const idCheck = errorPropertyCollection.find((e) => e.Id == props.Number);
          if (idCheck) {
            return idCheck[propertyName];
          }
        }
      };


  return (
    <>
    
 


        {

        props.errors? (
               (props.Number == null)? (
                props.errors[props.Name]? (
                    <p className="error">{props.errors[props.Name]}</p>
                  ):<></>
            ):(
                CheckErrors(props.Name) != null? (
                    <p className="error">{CheckErrors(props.Name)}</p>
                  ):<></>
            )

        ) : (<></>)
        

            // props.Number == null? (
            //     props.errors != null? props.errors[props.Name] && (
            //         <p className="error">{props.errors[props.Name]}</p>
            //       ):<></>
            // ):(
            //     props.errors != null? props.errors[props.Name] && (
            //         <p className="error">{props.errors[props.Name]}</p>
            //       ):<></>
            // )
        }


    {/* {props.Number == null? props.errors != null? props.errors[props.Name] && (
        <p className="error">{props.errors[props.Name]}</p>
      ):<></>} */}
    </>
    
  )
}

export default ErrorDisplay