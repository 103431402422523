import React from 'react'
import styles from './OurProducts.module.css'
import { Link } from 'react-router-dom'

function OurProducts() {
  return (
    <div className={styles.ourproducts}>
        
    <div className={styles.our__products__wrapper}>
    <h2 className={styles.ourproducts__header}>Our Products</h2>
      <img
        src="Images\content\key.png"
        alt="keyring"
        className={styles.keyring}
      />

      <img
        src="Images\content\bee.png"
        alt="Bee products"
        className={styles.bee}
      />
      <div className={styles.ourproducts__text}>
        <p>
          We have a broad range of products which are good quality, useful,
          and reusable in everyday life. Our containers and items are very
          versatile for all your recipients and occasions. Many of which can
          be personalised.
        </p>
        <p>
          We work with a number of independent businesses to offer you
          something new. We focus on British brands who are passionate about
          bringing quality and responsibly made products to the nation.
        </p>
      </div>          
      <img
        src="Images\content\jumper.png"
        alt="Childrens Jumper"
        className={styles.jumper}
      />
      <div className={styles.products__link}>
        <Link to="/Products">
          <button className={styles.product__button}>Products</button>
        </Link>
      </div>
    </div>
  </div>
  )
}

export default OurProducts