import React, { useState } from "react";

export default function UseOrderFormValidations() {
  const [selectedContainer, setValidationSelectedContainer] = useState(1);

  function isNumeric(myString) {
    return /\d/.test(myString);
  }

  function isValidPostCode(string){
    const ptn = /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;
    return ptn.test(string);
  }

  function validEmail(email) {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    );
  }

  const validations = {
    Ribbon: {
      custom: {
        isValid: (value) => value.length <= 30,
        message: "Ribbon text must be 30 characters or less.",
      },
    },
    GiftTag: {
      custom: {
        isValid: (value) => value.length <= 30,
        message: "Gift Tag text must be 30 characters or less.",
      },
    },
    Sticker: {
      custom: {
        isValid: (value) => value.length <= 50,
        message: "Sticker text must be 50 characters or less.",
      },
    },    
    Container: {
      Personalisation: {
        isValidEx: (value) => {
          switch (selectedContainer) {
            case 1:
              return ContainerPersonalisationVersionOne(value);
            case 2:
              return ContainerPersonalisationVersionTwo(value);
            // NO PERSONALISATION.
            default:
              return DefaultPersonalisation();
          }
        },
      },
    },
    Items: {
      PersonalisationRecord: {
        Validations: (value) => {
          switch (value.PersonalisationOption?.Type) {
            case 1:
              return ItemPersonalisationVersionOne(value);
            case 2:
              return ItemPersonalisationVersionTwo(value);
            case 3:
              return ItemPersonalisationVersionThree(value);

            default:
              return ItemPersonalisationDefault(value);
          }
        },
      },
    },
    ContactDetails: {
      propertyValidation: {
        FullName: {
          isValid: (value) =>
            value.length > 1 && value.length < 50 && !isNumeric(value),
          message: "Name must be 1-50 characters.",
        },
        TelephoneNumber: {
          isValid: (value) => isNumeric(value),
          message: "Telephone No. must be numeric.",
        },
        Email: {
          isValid: (value) => value != null && validEmail(value),
          message: "Email must be entered.",
        },
        Postcode: {
          isValid: (value) => value.length > 0,
          message: "Postcode must be entered."
        }
       
      },
    },
    DeliveryDetails: {
      propertyValidation: {
        FullName: {
          isValid: (value) =>
            value.length > 1 && value.length < 50 && !isNumeric(value),
          message: "Name must be 1-50 characters.",
        },
        HomeAddress: {
          isValid: (value) => value.length > 0,
          message: "Address must be entered."
        },
        Postcode: {
          isValid: (value) => isValidPostCode(value),
          message: "Postcode must be entered."
        }
      },
    },
    TC: {
      custom: {
        isValid: (value) => value == true,
        message: "You must agree to the Terms and Conditions.",
      },
    },
    Confirmation: {
      custom: {
        isValid: (value) => value == true,
        message: "You must confirm the details submitted are correct.",
      },
    },
    Payment: {
      custom: {
        isValid: (value) => value == true,
        message: "You must confirm you have understood the payment procedure."
      }
    }
  };

  const DefaultPersonalisation = () => {
    let itemValidations = [
      {
        isValid: (v) => true,
      },
    ];

    return itemValidations;
  };

  const ContainerName = (v) => {
    return {
      isValid: (v) => v["ContainerName"].length > 0,
      Property: "ContainerName",
      Message: "Name must be set.",
    };
  };

  const ContainerColour = (v) => {
    return {
      isValid: (v) => v["ContainerColour"].length > 1,
      Property: "ContainerColour",
      Message: "Colour must be set.",
    };
  };

  const ContainerText = (v) => {
    return {
      isValid: (v) => v["ContainerText"].length <= 150,
      Property: "ContainerText",
      Message: "Text must be 150 characters or less.",
    };
  };

  const validateTextColour = (v) => {
    if (v["TextColour"].length > 1) {
      if (v["ContainerText"].length < 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const validateColourOfDesign = (v) => {
    return {
      isValid: (v) => {
        return v["Design"].length < 1
          ? true
          : v["DesignColour"].length < 1
          ? false
          : true;
      },
      Property: "DesignColour",
      Message: "Design Colour must be set if Design is entered.",
    };
  };
  const validateContainerTextColour = (v) => {
    return {
      isValid: (v) => {
        return v["ContainerText"].length < 1
          ? true
          : v["TextColour"].length < 1
          ? false
          : true;
      },
      Property: "TextColour",
      Message: "Text Colour must be set if Text is entered.",
    };
  };

  const ContainerColourOnlyIfTextSet = (v) => {
    return {
      isValid: (v) => validateTextColour(v),
      Property: "ContainerText",
      Message: "Text must be entered if Text Colour is set.",
    };
  };

  const ContainerPersonalisationVersionOne = (v) => {
    let itemValidations = [];

    // container Name
    itemValidations.push(ContainerName(v));

    // colour
    itemValidations.push(ContainerColour(v));

    // container Text
    itemValidations.push(ContainerText(v));

    itemValidations.push(validateContainerTextColour(v));

    // checking that the TextColour isn't set if the container text is empty.
    itemValidations.push(ContainerColourOnlyIfTextSet(v));

    // check that if the text is set then the colour is also set.
    itemValidations.push(validateColourOfDesign(v));

    // Checking that design text is less than 100 characters.
    itemValidations.push({
      isValid: (v) => v["Design"].length <= 100,
      Property: "Design",
      Message: "Design Text must be 30 characters or less.",
    });

    itemValidations.push({
      isValid: (v) => {
        
        return v["DesignColour"].length < 1
          ? true
          : v["Design"].length < 1
          ? false
          : true;
      },
      Property: "DesignColour",
      Message: "Design must be entered if Design Colour is set.",
    });

    return itemValidations;
  };

  const ContainerPersonalisationVersionTwo = (v) => {
    let itemValidations = [];

    // container Name
    itemValidations.push(ContainerName(v));

    // colour
    itemValidations.push(ContainerColour(v));

    // container Text
    itemValidations.push(ContainerText(v));

    // checking that the TextColour isn't set if the container text is empty.
    itemValidations.push(ContainerColourOnlyIfTextSet(v));

    itemValidations.push(validateContainerTextColour(v));

    return itemValidations;
  };

  const ItemPersonalisationDefault = (v) => {
    let itemValidations = [];

    itemValidations.push({
      isValid: (v) => v["ItemName"].length >= 1 && v["ItemName"].length <= 150,
      Property: "ItemName",
      Message: "Name must be set.",
    });

    itemValidations.push({
      isValid: (v) => v["ItemSize"].length >= 1,
      Property: "ItemSize",
      Message: "Size must be set.",
    });

    itemValidations.push({
      isValid: (v) => v["ItemColour"].length >= 1,
      Property: "ItemColour",
      Message: "Colour must be set.",
    });

    return itemValidations;
  };

  const ItemTextValidation = (v) => {
    return {
      isValid: (v) => v["ItemText"] == undefined || v["ItemText"].length <= 30,
      Property: "ItemText",
      Message: "Text must be 30 characters or less.",
    };
  };

  const ItemTextColourValidation = (v) => {
    return {
      isValid: (v) =>
        v["ItemText"] != undefined && v["ItemText"]?.length > 0
          ? v["ItemTextColour"].length > 1
          : v["ItemTextColour"].length < 1,
      Property: "ItemTextColour",
      Message: "Text and Text Colour must both be set.",
    };
  };

  const ItemDesignValidation = (v) => {
    return {
      isValid: (v) => v["ItemDesign"]?.length <= 100,
      Property: "ItemDesign",
      Message: "Design description must be 100 characters or less.",
    };
  };

  const ItemPersonalisationVersionOne = (v) => {
    let itemValidations = [];

    

    ItemPersonalisationDefault(v).forEach((element) => {
      itemValidations.push(element);
    });

    itemValidations.push(ItemTextValidation(v));
    itemValidations.push(ItemTextColourValidation(v));
    itemValidations.push(ItemDesignValidation(v));

    itemValidations.push({
      isValid: (v) => {
      
          return v["ItemDesign"].length < 1
          ? true
          : v["ItemDesignColour"].length < 1
          ? false
          : true;
          
      },

      Property: "ItemDesignColour",
      Message: "Design Colour must be set if Design is entered.",
    });

    itemValidations.push({
      isValid: (v) => {
        return v["ItemDesignColour"].length < 1
        ? true
        : v["ItemDesign"].length < 1
        ? false
        :true;
      },
      Property: "ItemDesign",
      Message: "Design must be entered if Design Colour is set."
    })

    return itemValidations;
  };
  const ItemPersonalisationVersionTwo = (v) => {
    let itemValidations = [];

    ItemPersonalisationDefault(v).forEach((element) => {
      itemValidations.push(element);
    });
    itemValidations.push(ItemTextValidation(v));
    itemValidations.push(ItemTextColourValidation(v));
    itemValidations.push(ItemDesignValidation(v));
    return itemValidations;
  };
  const ItemPersonalisationVersionThree = (v) => {
    let itemValidations = [];

    ItemPersonalisationDefault(v).forEach((element) => {
      itemValidations.push(element);
    });

    itemValidations.push({
      isValid: (v) =>
        v["ChildsName"].length <= 30 && v["ChildsName"].length > 0,
      Property: "ChildsName",
      Message: "Child's Name must be entered, and 30 characters or less.",
    });

    itemValidations.push({
      isValid: (v) =>
        v["CouplesName"].length <= 30 && v["CouplesName"].length > 0,
      Property: "CouplesName",
      Message: "Couple's Name must be entered, and 30 characters or less.",
    });

    itemValidations.push({
      isValid: (v) => v["BrideGroomImage1"].length > 0,
      Property: "BrideGroomImage1",
      Message: "Bride/Groom Image must be set.",
    });

    itemValidations.push({
      isValid: (v) => v["BrideGroomImage2"].length > 0,
      Property: "BrideGroomImage2",
      Message: "Bride/Groom Image must be set.",
    });

    return itemValidations;
  };

  return { validations, setValidationSelectedContainer };
}
