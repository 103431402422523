import React, { useState } from "react";
import * as GoIcons from "react-icons/go";
import SelectModal from "./SelectModal";
import classes from "./ProductInformation.module.css";

function ProductInformation(props) {
  const [showModal, setShowModal] = useState(false);
  const hideModalHandler = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  const showModalHandler = (e) => {
    e.preventDefault();
    setShowModal(true);
  };


  const SafteryStyle = (e) => {
    if (e != undefined){
      
      return {color:e}
    }
    return {};
  }

  return (
    <>
      <GoIcons.GoInfo onClick={showModalHandler}></GoIcons.GoInfo>
      <SelectModal
        Show={showModal}
        HideModal={hideModalHandler}
        BtnClass={classes.btn__cls}
      >
        <div className={classes.ProductInfo}>
          <div className={classes.Name}>{props.Name}</div>
          <div className={classes.Info}>
            <div className={classes.InfoItem}>
              Description: {props.Description}
            </div>
            <div className={classes.InfoItem}>Materials: {props.Materials}</div>
            <div className={classes.InfoItem}>
              Washing Instructions: {props.WashingInstructions}
            </div>
            
            {/* </div><div className={classes.InfoItem}> */}

            {props.Warnings? 
                <div className={classes.InfoItem}>
                Safety Information: {" "} 
  
                <div style={{display:"inline-block"}}>
              
                {props.Warnings?.map((x, i) => (
                  <div key={i}  style={{color:x.TextColour}}>{x.Name}  </div>
                ))}
                </div>
              </div>:<></>}
            
          </div>
        </div>
      </SelectModal>
    </>
  );
}

export default ProductInformation;
