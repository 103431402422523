import React from "react";
import styles from "./HomeReviews.module.css";
import ReviewItem from "./ReviewItem";
import { Link } from "react-router-dom";
function HomeReviews() {
  const reviews = [
    {
      who: "Client",
      review:
        "Hannah was so easy to work with and created a bespoke hamper for my daughter and her new husband, personalised and gift wrapped beautifully. Quick efficient ordering and delivery. Highly recommended.",
    },
    {
      who: "Recipient",
      review:
        "Highly recommend. I got a personalised baby match hamper, the gifts were in a toy hamper with a personalised lamb, blanket (with her birthday, time of birth and her weight), ribbon and gift tag...",
    },
    {
      who: "Client",
      review:
        "Many thanks Match Hampers. You've obviously worked hard on this. It looks wonderful.",
    },
    {
      who: "Recipient",
      review:
        "I received this bag as a birthday present from a friend through Match Hampers and I love it! I would definitely recommend purchasing whether it's fro yourself or as a present for someone else!",
    },
  ];

  return (
    <div className={styles.reviews__wrapper}>
      <div className={styles.header}>Our Clients & Recipients</div>

      <div className={styles.review__list}>
        <div className={styles.reviewleft}>
          <ReviewItem who={reviews[0].who} review={reviews[0].review} />
        </div>
        <div className={styles.reviewbottom}>
          <ReviewItem who={reviews[1].who} review={reviews[1].review} />
        </div>
        <div className={styles.review__link}>
            <Link to="/Reviews">
              <button className={styles.hampers__button}>Read more</button>
            </Link>
          </div>
      </div>
    </div>
  );
}

export default HomeReviews;
