import React from 'react'
import classes from './ThemeHeader.module.css';
import { Link } from 'react-router-dom';
import ImageProvider from '../../../UI/Images/ImageProvider';
function ThemeHeader(props) {
  return (
    <div
      className={classes.theme__item}
      style={{ backgroundColor: props.Colour }}
    >
      <div className={classes.theme__heading}>
        <h3>{props.Name} Hampers</h3>
      </div>
      <div className={classes.theme__image}>
        <ImageProvider Img={props.Img} AltText={props.Name} />
      </div>
      <div className={classes.theme__description}>
        <p>{props.Description}</p>
      </div>
      <div className={classes.theme__instructions}>  
    {!props.IsProduct? (
    
        <p>Browse our set hampers, read the descriptions, and select the one you'd like. Make the products bespoke, add a gift wrap message, and fill in the delivery details. Personalisation and gift wrap are included with your hamper. </p>
    
    ):(
       <p>Having browsed our{" "}
        <Link to="/Products" className={classes.productLink}>
          `Products`
        </Link>{" "}
        you are now ready to create your unique hamper. Complete this order form
        outlining your choice of container and items, personalisation, gift wrap,
        contact details and delivery details.</p>
    )}
    </div>
      
    </div>
  )
}

export default ThemeHeader