import React from "react";
import { Link } from "react-router-dom";
import styles from "./HowItWorks.module.css";

function HowItWorks() {
  return (
    <div className={styles.how__it__works__wrapper}>
      <h2 className={styles.header}>How it works</h2>
      <div className={styles.question}>
        You've got an occasion coming up, you know you want a hamper, but where
        do you start?
      </div>
      <div className={styles.option1__title}> Option 1:</div>
      <div className={styles.option__one}>
        Browse our <Link to="/Products">Products</Link>: containers and items. Pick and choose
        exactly what products you would like to gift. View the images and check
        the options for making it bespoke. Is the product available in a range
        of colours or sizes? Can the product be personalised? Then, head to
        Hampers and choose "Create Your Own". Select the container and items,
        and make them bespoke. Add a gift wrap message and fill in the delivery
        details.
      </div>
      <img
        className={styles.how__it__works__image__one}
        src="/Images/content/About/how-it-works-1.png"
      ></img>
      <img
        className={styles.how__it__works__image__two}
        src="/Images/content/About/how-it-works-2.png"
      ></img>
      <div className={styles.option2__title}>Option 2:</div>
      <div className={styles.option__two}>
        Browse our set hamper collections. Head to <Link to="/Hampers">Hampers</Link> to view the images and
        read the descriptions, then select the one you'd like. Make the products
        bespoke, add a gift wrap message and fill in the delivery details. You
        can add extra items to your hamper, but be mindful of the size of the
        container so your gifts all fit in.
      </div>
      <img
        className={styles.how__it__works__image__three}
        src="/Images/content/About/how-it-works-3.png"
      ></img>
      <img
        className={styles.how__it__works__image__four}
        src="/Images/content/About/how-it-works-4.png"
      ></img>
      <div className={styles.option3__title}>Option 3:</div>
      <div className={styles.option__three}>
        Use our services and we can do it all for you. Tell us about
        who you're buying for, your budget, and we'll make hampers to suit your
        loved ones. Email us at <Link to="mailto:contact@matchhampers.com">
                contact@matchhampers.com
              </Link> to book an appointment today.
      </div>
    </div>
  );
}

export default HowItWorks;
