import React from "react";
import styles from "./GiftWrap.module.css";

function GiftWrap() {
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Why are presents traditionally wrapped?</h2>
      <div className={styles.why}>
        Let's start off by looking back at where gift wrap started and why we
        still wrap our presents today. One reason we continue to wrap products
        is to add protection around them. However, it was more traditionally
        desirable to wrap a gift to hide it's identity until just the right
        moment. A third reason is that gift wrap elevates the present to be more
        superior than other presents. Is gift wrap important to you and your
        loved ones?
      </div>
      <div className={styles.giftwrapExamples}>
        <img
          className={styles.example__image}
          src="/Images/content/GiftWrap/mh-gift-wrap.png"
          alt="Pressent gift wrapped"
        ></img>
        <img
          className={`${styles.example__image} ` + `${styles.example_image_two}`}
          src="/Images/content/GiftWrap/beer-bucket-wrapped.png"
          alt="Beer bucket gift wrapped"
        ></img>
        <img
          className={styles.example__image}
          src="/Images/content/GiftWrap/mh-christmas-wrap.png"
          alt="Numerous gifts wrapped"
        ></img>
      </div>
      <h2 className={styles.how__header}>How are our hampers gift wrapped?</h2>
      <div className={styles.how}>
        Your chosen items are placed in the container. The container is wrapped
        in tissue paper and tied with a ribbon. The ribbon is finished in a bow
        and personalised with your message. A gift tag is attached to the hamper
        with your message on the back. The hamper is then placed in a box for
        posting. On the outside of the box is a sticker with your message.
      </div>
      <div className={styles.mh__giftwrapExamples}>
        <img
          className={styles.example__image}
          src="/Images/content/GiftWrap/wrapped-trug.png"
          alt="Trug wrapped"
        ></img>
        <img
          className={styles.example__image}
          src="/Images/content/GiftWrap/wedding-present.png"
          alt="Wedding pressent wrapped"
        ></img>
      </div>
      <h2 className={styles.what__header}>What options are there for gift wrap?</h2>
      <div className={styles.what__wrapper}>
        <div className={styles.ribbon}>
          <h3>Message on the Ribbon</h3>
          <div>
            This message is short and bold. It will be pressed onto the ribbon
            ends (the same message repeated on both ends). E.g. "Baby Lyra".
          </div>
        </div>
        <img className={`${styles.what__image__one} ` + `${styles.example__image}`}
            src="/Images/content/GiftWrap/what-one.png"
            alt="Wedding pressent wrapped"
          ></img>
        <div className={styles.giftag}>
          <h3>Message on the Gift Tag</h3>
          <div>
            This message is printed on one side of the gift tag. We recommend
            including who the gift is for, the reason for sending, and who the
            gift is from. E.g. "To Lyra, Welcome to the world! With love from
            Peter and Jenny xxx".
          </div>
        </div>
        <div className={styles.sticker}>
          <h3>Message on the Sticker</h3>
          <div>
            This message will be printed on the sticker that will be placed on
            the outside of the postal box. Only write a message if it's relevant
            for the recipient (if you want the gift to be opened on a specific
            date). E.g. "Happy Birthday - Open on 4th May 2022".
          </div>
        </div>
        <img className={`${styles.what__image__two} ` + `${styles.example__image}`}
            src="/Images/content/GiftWrap/stickers.png"
            alt="Wedding pressent wrapped"
          ></img>
         
        
      </div>
    </div>
  );
}

export default GiftWrap;
