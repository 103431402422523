import React from 'react'



export default function InitialValues(){
    const intialValues = {
        Hamper: {
          HamperName: "",
        },
        Container: {
          ContainerName: "",
          ContainerColour: "",          
          ContainerText: "", 
          TextColour: "",
          Design: "",
          DesignColour: ""
        },
        Items: [
          {
            ItemName: "",
            ItemSize: "",
            ItemColour: "",            
            ItemTextColour: "",
            ItemDesignColour: ""
          },
        ],
        Ribbon: "",
        GiftTag: "",
        Sticker: "",
        InsideNote:"",
        ContactDetails: {
          FullName: "",
          TelephoneNumber: "",
          Email: "",
          HomeAddress: "",
          Postcode: "",
          HearAbout:""
        },
        DeliveryDetails: {
          FullName: "",
          TelephoneNumber: "",
          Email: "",
          HomeAddress: "",
          Postcode: ""
        },
        AdditionalComments: "",
        TC: false,
        Confirmation: false,             
        Payment: false
      };

      return intialValues;
}