import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navigation.module.css";

function Navigation(props) {
  return (
    <nav
      className={
        `${styles.navigation} ` + `${props.checked ? styles.checked : ""}`
      }
    >
      <div className="navigation-header">
        {/* <h1 className={styles.navigation__heading}>Where to...</h1> */}
      </div>
      <ul className={styles.navigation__list}>
        <li className={styles.navigation__item}>
          <Link to="/" className={styles.navigation__link}>
            Home
          </Link>
        </li>
        <li className={styles.navigation__item}>
          <Link to="/About-Us" className={styles.navigation__link}>
            About
          </Link>
        </li>        
        <li className={styles.navigation__item}>
          <Link to="/Products" className={styles.navigation__link}>
            Products
          </Link>
        </li>
        <li className={styles.navigation__item}>
          <Link to="/Hampers" className={styles.navigation__link}>
            Hampers
          </Link>
        </li>
        <li className={styles.navigation__item}>
          <Link to="/Reviews" className={styles.navigation__link}>
            Reviews
          </Link>
        </li>
      </ul>
      <div className={styles.copywrite}>
        <div className={styles.MH}>Match Hampers {" "}</div> 
        <div>{" "} &copy; 2023</div>
      </div>
    </nav>
  );
}

export default Navigation;
