import React from "react";
import styles from "./Homepage.module.css";
import { Link } from "react-router-dom";
import HomeProduct from "../../Components/Home/Products/HomeProduct";
import HomePersonalisation from "../../Components/Home/HomePersonalisation/HomePersonalisation";
import OurProducts from "../../Components/Home/OurProducts/OurProducts";
import Reviews from "../../Components/Reviews/Reviews";
import HomeReviews from "../../Components/Home/Reviews/HomeReviews";
function HomePage() {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.banner}>
          <h1 className={styles.heading}>Match Hampers</h1>
          <h2 className={styles.tag__line}>
           -&nbsp;stay connected with your loved ones' celebrations&nbsp;-
          </h2>
        </div>
      </header>
      <div className={styles.about}>
        <div className={styles.about__questions}>
          <div>
            Do you want to send quality, meaningful and personalised gifts?
          </div>
          <div>
            Do you struggle with the time, stress and responsibility of
            organising gifts for the whole family?
          </div>
        </div>
        <div className={styles.about__content}>
          <h2 className={styles.aboutus__heading}>About Us</h2>
          <div className={styles.aboutus__left}>
            <div className={styles.aboutus__text}>
              We'll ensure you never miss a birthday again and are free from the
              hassle of Christmas shopping. We'll tailor gifts and hampers to
              your loved ones through our bespoke service.
            </div>
            <Link to="/About-Us" className={styles.aboutus__link}>
              <button className={styles.about__button}>Read More</button>
            </Link>
          </div>

          <img
            className={styles.aboutus__image}
            src="Images/content/aboutus.jpg"
            alt="new home hamper"
          ></img>
        </div>
      </div>
      <div className={styles.ourhampers}>
        <div className={styles.ourhamper__top}>
          <h2 className={styles.hamper__header}>Our Hampers</h2>
          <div className={styles.hamper__text}>
            We are building on the tradition of giving a hamper as a gift. To
            us, a hamper comprises of a container with handpicked items inside.
            We cater for every occasion making your friends and family feel loved and cherished all year round.
          </div>
          <img
            className={styles.hamperimageleft}
            src="Images/content/crafting-queen.png"
            alt="crafting queen"
          ></img>
          <img
            className={styles.hamperimageright}
            src="Images/content/bbq-king.png"
            alt="crafting queen"
          ></img>
        </div>
        <div className={styles.hamper__products__wrapper}>
          <div className={styles.hamper__products}>
            <HomeProduct
              className={styles.hamperproductleft}
              image="Images/content/mum-to-be.png"
              alt="mum to be hamper"
              name="Mum-To-Be-Hamper"
              price="£82"
              link="hampers/Baby/mum-to-be"
            />
            <HomeProduct
              image="Images\Themes\Baby\premium-dad.png"
              alt="Premium Dad's Birthday Hamper"
              name="Premium Dad's Birthday Hamper"
              price="£132"
              link="hampers/Birthday/Premium-Dads-Birthday"
            />
            <HomeProduct
              className={styles.hamperproductleft}
              image="Images\Themes\Birthday\ultimate-holiday.png"
              alt="Ultimate Holiday Hamper"
              name="Ultimate Holiday Hamper"
              price="£133"
              link="hampers/Birthday/Ultimate-Holiday"
            />
            <HomeProduct
              className={styles.hamperproductleft}
              image="Images\Themes\Pets\puppy.png"
              alt="Puppy Hamper"
              name="Puppy Hamper"
              price="£59"
              link="hampers/Pets/Puppy"
            />
            <HomeProduct
              image="Images\Themes\Baby\premium-nursery.png"
              alt="Premium Nursery Hamper"
              name="Premium Nursery Hamper"
              price="£80"
              link="hampers/Baby/premium-nursery"
            />
            <HomeProduct
              image="Images\Themes\Education\off-to-university.png"
              alt="Off to University Hamper"
              name="Off to University Hamper"
              price="£121"
              link="hampers/Education/Off-To-University"
            />
          </div>
          <div className={styles.hampers__link}>
            <Link to="/Hampers">
              <button className={styles.hampers__button}>Hampers</button>
            </Link>
          </div>
        </div>
      </div>
      <OurProducts />
      <HomePersonalisation />
      <HomeReviews />
    </>
  );
}

export default HomePage;
