import React, { useState } from "react";
import Dropdown from "../UI/Dropdown";
import Textbox from "../UI/Textbox";
import TextArea from "../UI/TextArea";
import classes from "./Container.module.css";
import SelectModal from "../UI/Modal/SelectModal";
import ItemSelection from "../UI/Modal/Selection/ItemSelection";
import ClearPersonalisation from "../Items/ClearPersonalisation";
import Placeholder from "../UI/Placeholder";
import PlaceholderClasses from "../UI/PlaceHolderSet.module.css";
import ErrorDisplay from "../UI/ErrorDisplay";
import PersonalisationTwoTextInfo from "../UI/Information/PersonalisationTwoTextInfo";
import PersonalisationTwoDesignInfo from "../UI/Information/PersonalisationTwoDesignInfo";
import modalClass from '../UI/Modal/Modal.module.css';

function ContainerOptionTwo(props) {
  const clearPersonalisation = (e) => {
    e.preventDefault();

    props.updatePropertyValue("Container", "ContainerText", "");
    props.updatePropertyValue("Container", "TextColour", "");
    props.updatePropertyValue("Container", "Design", "");
  };

  const [showModal, setShowModal] = useState(false);
  const hideModalHandler = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  const showModalHandler = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleItemSelction = (i, name) => {
    setShowModal(false);
    props.handleChangeSpreadDirect("Container", "Design", i);
  };

  const handleChosenSelection = (items) => {
    setShowModal(false);
    if (items.length == 1) {
      handleItemSelction(items[0].name);
    }
  };
  const personalisationSentence = "Personalisation can be left blank if preferred.";
  return (
    <Placeholder className={PlaceholderClasses.placeholderItem}>
    <label className={PlaceholderClasses.placeholderLabel}>Personalisation</label>
    <label className={classes.container__Personalisation__Sentince}>{personalisationSentence}</label>
      <div className={classes.Container__Grid__Personalisation}>
        <TextArea
          LabelValue="Container Text:"
          type="text"
          Name="ContainerText"
          Value={props.data.Container.ContainerText}
          ValueChanged={props.handleChangeSpread("Container", "ContainerText")}
          className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
          Columns="40"
          Rows="5"
          errors={props.errors}
          errorName={"Container.ContainerText"}
          Info={<PersonalisationTwoTextInfo Location="Container" />}
        />
         {props.errors.ContainerContainerText && (
            <p className="error">{props.errors.ContainerContainerText}</p>
          )}
        <Dropdown
          LabelValue="Container Text Colour:"
          Name="ContainerTextColour"
          Value={props.data.Container.TextColour}
          DefaultText="Select colour for container"
          ValueChanged={props.handleChangeSpread("Container", "TextColour")}
          className={classes.Container__GridItem}
          Options={props.TextColours}
          errors={props.errors}
          errorName={"Container.TextColour"}
        />
          {props.errors.ContainerTextColour && (
            <p className="error">{props.errors.ContainerTextColour}</p>
          )}

        {/* <Dropdown
        LabelValue="Container Design:"
        type="text"
        Name="ContainerDesign"
        Value={props.data.Container.Design}
        ValueChanged={props.handleChangeSpread("Container", "Design")}
        className={classes.Container__GridItem}
        Options={props.Options}
        DefaultText="Select Container design"
        errors={props.errors}
          errorName={"Container.Design"}

      /> */}
      </div>
      {/* <div style={{position:`relative`}}> */}
      <label className={classes.BrideGroom__Label}>
        Container Design: {props.data.Container.Design}
        <PersonalisationTwoDesignInfo Location="Container" btnClass={modalClass.closeButton}/>
      </label>
      {/* <ErrorDisplay errors={props.errors} Name= {"BrideGroomImage1"} Number={props.Number} /> */}
      <button onClick={showModalHandler}>Choose</button>
      <SelectModal Show={showModal} HideModal={hideModalHandler}>
        <ItemSelection
          items={props.Options}
          HandleItemSelction={handleItemSelction}
          Max={["Container Design"]}
          handleChosenSelection={handleChosenSelection}
          Instuction={<div>Please select <b>one</b> design. Then, click `next' to review your selection. To undo, click the selected design to remove and select the new one.</div> }
        />
      </SelectModal>
      
      {/* </div> */}
      {props.errors.ContainerDesign && (
            <p className="error">{props.errors.ContainerDesign}</p>
          )}
      <div></div>
      <ClearPersonalisation
        clearPersonalisationHandler={clearPersonalisation}
      />
    </Placeholder>
  );
}

export default ContainerOptionTwo;
