import React, { useEffect, useState } from "react";
import OrderItem from "./OrderItem";
import axios from "axios";
import classes from "./OrderItem.module.css";
function ItemList(props) {
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const ctrns = await axios("/JSON/Items.json", {
        responseType: "json",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setProductList(ctrns.data);      
    };
    fetchProducts();
  }, []);

  return (
    <>
      <div className="ordFromHeader">Item(s)</div>
      <div className="ordFromItems">
        <h5>
          Please choose the size, colour and personalisation for all items (where
          applicable).
        </h5>
        {Array.isArray(props.data.Items)
          ? props.data.Items.map((m, i) => (
            <div key={i}>
            {i > 0 && m.setItem == null ? (
                <button key={`btn`+i}
                  className={classes.btnDeleteItem}
                  onClick={(e) => {props.DeleteItemRow(e, i)}}
                >
                  Delete Item
                </button>
              ) : null}
              <OrderItem
                key={i}
                Number={i}
                data={props.data}
                handleChange={props.handleChangeSpreadNested}
                errors={props.errors}
                deleteItem={props.DeleteItemRow}
                setItem={m.setItem}
                GetProductItems={props.GetProductItems}
                GetProductSize={props.GetProductSize}
                GetProductColour={props.GetProductColour}
                handleChangeSpreadNestedDirect={props.handleChangeSpreadNestedDirect}
                GetPersonalisationOption={props.GetPersonalisationOption}
                GetDesignOptions={props.GetDesignOptions} 
                GetProductImage={props.GetProductImage}   
                GetOptionName={props.GetOptionName}           
                GroupProductsInCategories={props.GroupProductsInCategories}    
                GetTextColour={props.GetTextColour}    
                GetInformation={props.GetInformation}        
              />
              
              </div>
            ))
          : null}


        <button onClick={props.AddItemRow}>Add Item</button>
      </div>
    </>
  );
}

export default ItemList;
