import React, {useState, useEffect} from "react";
import styles from "./Reviews.module.css";
import axios from "axios";
import ReviewCard from "../../Components/Reviews/ReviewCard";
import { Link } from "react-router-dom";

function Reviews() {

    const [reviews,setReviews] = useState([]);
    useEffect(() => {
        const fetchReviews = async () => {
          const ctrns = await axios("/JSON/Reviews.json", {
            responseType: "json",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          setReviews(ctrns.data);
        };
        fetchReviews();
      }, []);


  return (
    <div className={styles.review__wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Client & Recipient Reviews</h2>      
        <div className={styles.header__content}>
        <p>
        Who is a client?
        </p>
        <p>
        A client is someone who uses our gift service and purchases gifts and hampers. 
        </p>
        <p>
        Who is a recipient?
        </p>
        <p>
        A recipient is someone who receives a hamper.
        </p>
        <p>
        We really appreciate your feedback. If you would like to leave a review, please email your comments to <Link to="mailto:contact@matchhampers.com"> contact@matchhampers.com.</Link> 
        </p>
        </div>
        <div className={styles.header__image}>
          <img
            src="/Images/Reviews/ReviewHeader.png"
            alt="Customer supplied image of their personalised beach bag."
          />
        </div>
      </div>
      <div className={styles.reviews}>
        
            {reviews.map((r,i) => (

<ReviewCard key={i} ReviewType={r.ReviewType} GiftType={r.GiftType} Date={r.Date} ReviewContent={r.ReviewContent}/> 

            ))}
              
               
           
      </div>
    </div>
  );
}

export default Reviews;

{/* */}