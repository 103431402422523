import React from "react";
import styles from "./EcoMessage.module.css";
function EcoMessage() {
  return (
    <div className={styles.container}>
      <h2 className={styles.why__header}>Why is it important to be a sustainable business?</h2>
      <div className={styles.why}>
        <div className={styles.why__text}>
          It is true when you hear the phrase "There is no Planet B". We have to
          all contribute to being kind to this world and making changes to live
          sustainably - including Match Hampers. We need to be living today and
          enjoying life while preserving the planet for future generations. To
          be sustainable, means to be maintained at a certain rate or level. To
          be a sustainable business, means to reduce negative environmental
          impact resulting from our operations. We are passionate about giving
          quality and meaningful gifts, but how do we do this sustainably?
        </div>
        <img
          className={styles.why__image}
          src="/Images/content/Eco/River.png"
          alt="IMage of a River"
        ></img>
      </div>
      <h2 className={styles.how__header}>How is Match Hampers being sustainable?</h2>
      <div className={styles.how}>
        <div className={styles.how__text}>
          <p>
            We start by sourcing sustainably made products by independent
            British companies for many of our containers and items. We select
            meaningful products which are bespoke to your recipients and
            occasions so that you're giving the right presents. We source
            products which are good quality, long lasting and reusable, to
            reduce waste. By supporting local and national businesses, we are
            lowering our carbon footprint.{" "}
          </p>

          <p>
            We use recycled or recyclable packaging. The hamper will arrive in a
            cardboard box which can be recycled or reused. There may be some
            protective packaging such as shredded paper which can be recycled,
            or bubble wrap which can be reused. We don't pass on unnecessary
            waste, but we do want our hampers to arrive safely. In terms of the
            gift wrap, the tissue paper can be recycled, the ribbon can be
            trimmed and reused, and the gift tag can be recycled or kept as a
            keepsake.{" "}
          </p>

          <p>
            We aim to reduce waste to our clients and recipients. We have a
            recycling bin at Match Hampers headquarters, but try to use up any
            scraps before putting them in the bin. All communication is carried
            out digitally, so if your hampers is going straight to your
            recipient then you, as the client, will not receiving anything
            physical, and thus reducing waste.{" "}
          </p>
        </div>
        <img
          className={styles.how__image}
          src="/Images/content/Eco/Good-to-Bee.png"
          alt="Image of Good to Bee dish cloths"
        ></img>
      </div>
      <h2 className={styles.what__header}>What does this mean for you?</h2>
      <div className={styles.What}>
        <div className={styles.What__text}>
          When you purchase a hamper from Match Hampers, you are supporting
          British, independent businesses who are making conscious efforts to be
          sustainable. You are sending meaningful gifts which are long lasting,
          eco-friendly, and have a lower carbon footprint. You are shopping
          sustainably and encouraging others to do the same. You are being
          mindful of the environment and the impact of the products you
          purchase. You are reducing waste and preserving the planet for future
          generations.
        </div>
        <img
          className={styles.What__image}
          src="/Images/content/Eco/Flowers.png"
          alt="Vase of Flowers"
        ></img>
      </div>
    </div>
  );
}

export default EcoMessage;
