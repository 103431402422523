import React from 'react'
import classes from './Modal.module.css'

function SelectModal(props) {



  return (
    
    
    <div className={props.Show? `${classes.modal} ${classes.displayBlock}` : classes.displayHide}>
      <button type='button' onClick={props.HideModal} className={ props.BtnClass? props.BtnClass : classes.closeButton}>x</button>
      <section className={classes.modal__main}>
        {props.children}
       
      </section>
    
    </div>
    
  )
}

export default SelectModal
