import React from "react";
import Dropdown from "../UI/Dropdown";
import Textbox from "../UI/Textbox";
import TextArea from "../UI/TextArea";
import ErrorDisplay from "../UI/ErrorDisplay";
import classes from "./Container.module.css";
import ClearPersonalisation from "../Items/ClearPersonalisation";
import Placeholder from "../UI/Placeholder";
import PlaceholderClasses from "../UI/PlaceHolderSet.module.css";
import PersonalisationOneTextInfo from "../UI/Information/PersonalisationOneTextInfo";
import PersonalisationOneDesignInfo from "../UI/Information/PersonalisationOneDesignInfo";

function ContainerOptionOne(props) {
  const personalisationSentence =
    "Personalisation can be left blank if preferred.";
  const clearPersonalisation = (e) => {
    e.preventDefault();

    props.updatePropertyValue("Container", "ContainerText", "");
    props.updatePropertyValue("Container", "TextColour", "");
    props.updatePropertyValue("Container", "Design", "");
    props.updatePropertyValue("Container", "DesignColour", "");
  };

  return (
    <Placeholder className={PlaceholderClasses.placeholderItem}>
      <label className={PlaceholderClasses.placeholderLabel}>
        Personalisation
      </label>
      <label className={classes.container__Personalisation__Sentince}>
        {personalisationSentence}
      </label>

      <div className={classes.Container__Grid__Personalisation}>
        <div>
          <TextArea
            LabelValue="Container Text:"
            type="text"
            Name="ContainerText"
            Value={props.data.Container.ContainerText}
            ValueChanged={props.handleChangeSpread(
              "Container",
              "ContainerText"
            )}
            className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
            Columns="40"
            Rows="5"
            errors={props.errors}
            errorName={"Container.ContainerText"}
            Info={<PersonalisationOneTextInfo Location="Container" />}
          />
          
          {props.errors.ContainerTextColour && (
            <p className="error">{props.errors.ContainerTextColour}</p>
          )}
        </div>

        <Dropdown
          LabelValue="Container Text Colour:"
          Name="ContainerTextColour"
          Value={props.data.Container.TextColour}
          DefaultText="Select colour for container text"
          ValueChanged={props.handleChangeSpread("Container", "TextColour")}
          className={classes.Container__GridItem}
          Options={props.TextColours}
          errors={props.errors}
          errorName={"Container.TextColour"}
        />
        {props.errors.ContainerContainerText && (
          <p className="error">{props.errors.ContainerContainerText}</p>
        )}

        <Textbox
          LabelValue="Container Design:"
          type="text"
          Name="ContainerDesign"
          Value={props.data.Container.Design}
          ValueChanged={props.handleChangeSpread("Container", "Design")}
          className={classes.Container__GridItem}
          errors={props.errors}
          errorName={"Container.Design"}
          Info={<PersonalisationOneDesignInfo Location="Container"/>}
        />

        <Dropdown
          LabelValue="Container Design Colour:"
          Name="ContainerDesignColour"
          Value={props.data.Container.DesignColour}
          DefaultText="Select colour for design"
          ValueChanged={props.handleChangeSpread("Container", "DesignColour")}
          className={classes.Container__GridItem}
          Options={props.TextColours}
          errors={props.errors}
          errorName={"Container.DesignColour"}
        />

        {props.errors.ContainerDesignColour && (
          <p className="error">{props.errors.ContainerDesignColour}</p>
        )}
      </div>

      <ClearPersonalisation
        clearPersonalisationHandler={clearPersonalisation}
        className={classes.clearPersonalisation}
      />
    </Placeholder>
  );
}

export default ContainerOptionOne;
