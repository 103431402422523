import React, { useEffect } from "react";
import TextArea from "../UI/TextArea";
import Dropdown from "../UI/Dropdown";
import Textbox from "../UI/Textbox";
import ClearPersonalisation from './ClearPersonalisation';
import classes from './OrderItem.module.css'
import PersonalisationOneTextInfo from "../UI/Information/PersonalisationOneTextInfo";
import PersonalisationOneDesignInfo from '../UI/Information/PersonalisationOneDesignInfo';
import modalClass from '../UI/Modal/Modal.module.css';

function OrderItemPersonalisationOne(props) {

// useEffect(() => {
//    // personalisation option 1 & 2
//    props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemText", "");
//    props.handleChangeSpreadNestedDirect(
//      "Items",
//      props.Number,
//      "ItemTextColour",
//      ""
//    );
//    props.handleChangeSpreadNestedDirect(
//      "Items",
//      props.Number,
//      "ItemDesign",
//      ""
//    );
//    props.handleChangeSpreadNestedDirect(
//      "Items",
//      props.Number,
//      "ItemDesignColour",
//      ""
//    );

// },[props.data.Items[props.Number].ItemName]);


  const clearPersonalisation = (e) => {
    e.preventDefault();
    props.handleChangeSpreadNestedDirect("Items", props.Number, "ItemText", "");
    props.handleChangeSpreadNestedDirect("Items",props.Number,"ItemTextColour","");
    props.handleChangeSpreadNestedDirect("Items",props.Number,"ItemDesign","");
    props.handleChangeSpreadNestedDirect("Items",props.Number,"ItemDesignColour","");
  };

  return (
    // <div className={props.className}>
    <>
    <div className={classes.Item__Grid__Two}>
      <TextArea
        LabelValue="Item Text:"
        type="text"
        Name="ItemText"
        Value={props.data.Items[props.Number].ItemText}
        ValueChanged={props.handleChange("Items", props.Number, "ItemText")}
        //className={`${classes.Container__GridItem} ${classes.Container__Personalisation}}`}
        Columns="40"
        Rows="5"
        errors={props.errors}
        errorName={"ItemText"}
        Number={props.Number}
        Info={<PersonalisationOneTextInfo Location="Item" btnClass={modalClass.closeButton}/>}
      />
      <Dropdown
        LabelValue="Item Text Colour:"
        Name="ItemTextColour"
        Value={props.data.Items[props.Number].ItemTextColour}
        DefaultText="Select colour for item text"
        ValueChanged={props.handleChange(
          "Items",
          props.Number,
          "ItemTextColour"
        )}
        //    className={classes.Container__GridItem}
        Options={props.TextColours}
        errors={props.errors}
        errorName={"ItemTextColour"}
        Number={props.Number}
      />
      <Textbox
        LabelValue="Item Design:"
        Type="text"
        Name="ItemDesign"
        Value={props.data.Items[props.Number].ItemDesign}
        ValueChanged={props.handleChange("Items", props.Number, "ItemDesign")}
        errors={props.errors}
        errorName={"ItemDesign"}
        Number={props.Number}
        Info={<PersonalisationOneDesignInfo Location="Item" btnClass={modalClass.closeButton}/>}
        //  className={classes.Container__GridItem}
      />
      <Dropdown
        LabelValue="Item Design Colour:"
        Name="ItemDesignColour"
        Value={props.data.Items[props.Number].ItemDesignColour}
        DefaultText="Select colour for design"
        ValueChanged={props.handleChange(
          "Items",
          props.Number,
          "ItemDesignColour"
        )}
        //  className={classes.Container__GridItem}
        Options={props.TextColours}
        errors={props.errors}
        errorName={"ItemDesignColour"}
        Number={props.Number}
      />{" "}
      
      
      {/* <button onClick={clearPersonalisation}>Clear Personalisation</button> */}
    </div>
    <ClearPersonalisation clearPersonalisationHandler={clearPersonalisation} />
    </>
  );
}

export default OrderItemPersonalisationOne;
