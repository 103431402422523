import React from "react";
import { FaZhihu } from "react-icons/fa";
import ImageProvider from "../UI/Images/ImageProvider";
import classes from "./ProductItem.module.css";

function ProductItem(props) {
  return (
    <div className={classes.ProductItem}>
      <ImageProvider Img={props.Img} AltText={props.Name + ` img`} />
      <div>
        <label className={classes.Name}>{props.Name}</label>
        <div className={classes.Description}>{props.Description}</div>
        <div>
          {props.Materials.length > 0 ? (
            <>
              <label>Materials:</label>
              {props.Materials}
            </>
          ) : (
            <></>
          )}
        </div>

        {props.Warnings?.length > 0 ? (
          <div>
            <label>Safety Information:</label>
            {props.Warnings?.map((x, i) => (
              <div
                key={i}
                style={{ color: x.TextColour, display: "inline-block" }}
              >
                {x.Name}{" "}
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}

        <div className={classes.SizeColours}>
          <div className={classes.SizeColourItem}>
            <label>Sizes Avaliable:</label>
            <div>
              {Array.isArray(props.Sizes) ? (
                props.Sizes.map((x, i) => <div key={i}> {x} </div>)
              ) : (
                <div>{props.Sizes}</div>
              )}
            </div>
          </div>
          <div className={classes.SizeColourItem}>
            <label>{(props.OptionName != undefined || props.OptionName != null)? props.OptionName + ":"  : "Colours Avaliable:"}</label>
            <div>
              {props.Colours.map((x, i) => (
                <div key={i}>{x}</div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.Price}>
          <h3>£{props.Price}</h3>
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
