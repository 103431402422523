import React from 'react'
import styles from './ReviewCard.module.css'
function ReviewCard(props) {


    const footer = (giftType,date) => {
        return giftType + ", " + date;
    }

  return (
    <div className={styles.reviewCard}>
        <div className={styles.reviewContent}>
            "{props.ReviewContent}"
        </div>
        <div className={styles.review__footer}>
            <div className={styles.who}>
                {props.ReviewType} Review
            </div>
            <div className={styles.date}>
                 {footer(props.GiftType,props.Date)}
            </div>
        </div>
    </div>
  )
}

export default ReviewCard