import React from "react";
import styles from "./Team.module.css";
function Team() {
  return (
    <div className={styles.container}>
      <div className={styles.Hannah}>
        <h2 className={styles.director__header}>Hannah, Director</h2>
        <div className={styles.director__comment}>
          <p>
            {" "}
            Hannah, our director, deals with the day-to-day running of the
            business. She wears the hat for operations manager, photographer,
            sales assistant, head of branding and marketing, customer service
            manager, office assistant, embroiderer, head of buying, social media
            manager, finance administrator, and more.
          </p>

          <p>
            Before starting Match Hampers, Hannah studied at the University of
            Winchester and taught in Primary Schools across Jersey, Channel
            Islands. She's always loved the idea of creating an experience with
            gifts. She likes that when someone is given a hamper, they can appreciate the beautiful
            gift wrap and then open it to find multiple presents inside. She loves the sheer
            happiness it brings the giver to present someone with the right
            gift.
          </p>

          <p>
            In her spare time, Hannah loves creating memories with friends and
            family. She enjoys time outside: whether that's the garden, the Peak
            District, or in the sea when she visits Jersey.
          </p>

          <p>
            Her favourite quote is "They don't care what you know, until they
            know that you care".
          </p>
        </div>
        <img
          className={styles.director__image}
          src="/Images/content/Team/Hannah.png"
          alt="Hannah Director of Match Hampers"
        ></img>
      </div>
      <div className={styles.who}>
        <div className={styles.who__text}>
            <p>
            One other person needs mentioning as part of 'The Team'. He works behind the scenes and out of the spotlight. However, if you join us at one of our in-person events, you'll be sure to meet him there!
            </p>
            <p>
            His favourite quote is "No Pressure: No Diamonds".
            </p>
           
        </div>
        <img
          className={styles.who__image}
          src="/Images/content/Team/Who.png"
          alt="Unknown person taking photo."
        ></img>
      </div>
    </div>
  );
}

export default Team;
