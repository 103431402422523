import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export function UseHamper(props) {
  const [HamperList, setHamperList] = useState([]);
  const { ThemeName, hamperName } = useParams();
  const IsCreateYourOwn = () => {
    return ThemeName === props.defaultName || ThemeName === undefined;
  };

 

  useEffect(() => {
    const fetchHampers = async () => {



      // Theme route.
      if (!IsCreateYourOwn()) {
        let themeurl = "/JSON/" + ThemeName + ".json";

        const hampers = await axios(themeurl, {
          responseType: "json",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        setHamperList(hampers.data);

       props.handleChangeDirect("Container", {
          ContainerName: hampers.data[0].Name,
          ContainerColour: "",          
        });
        if (hamperName) {
          

          const hName = hampers.data.find(
            (x) => x.URLName.toLowerCase() === hamperName.toLowerCase()
          );
          if (hName) {
            
            const hamperName = props.handleChangeSpreadDirect(
              "Hamper",
              "HamperName",
              hName.HamperName
            );

            props.setShowContent(true);
          }
        } else {
          props.handleChangeSpreadDirect("Hamper", "HamperName", "");
          props.setShowContent(false);
        }
      } else {
        // Create your own.

        props.handleChangeSpreadDirect(
          "Hamper",
          "HamperName",
          "Create your Own"
        );

        props.setShowContent(true);
      }
    };
    fetchHampers();
  }, [ThemeName, hamperName]);

  // effect to set the product list, for when a Hamper is selected.
  

  const Hampers = () => {
    return [...HamperList];
  };

return {
    
    HamperList
}

}
