import React from 'react'
import styles from './HomeProduct.module.css';
import { Link } from 'react-router-dom';

function HomeProduct(props) {
  return (
    <div className={styles.home__product}>
        <Link className={styles.product__link} to={props.link}>
        <img className={styles.image} src={props.image} alt={props.alt} />
        <div className={styles.name__description}>
            <div>
            {props.name}
            </div>
            <div>
            {props.price}
            </div>
            
        </div>
        </Link>
    </div>
  )
}

export default HomeProduct